import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import {
  cvpDiagram,
  cvpDiagramEn,
  whatIsCling,
  expandability,
  convenience,
  reliability,
  wave1,
  btnTop,
  btnPlus,
  btnClose,
  clingSwap,
  iconDiscord,
  iconTelegram,
  iconTwitter,
  iconMedium,
} from '../../../assets/img';
import i18n from 'i18next';
import { useMediaQuery } from 'react-responsive';

const WhatIsCling = () => {
  const [isShown, setIsShown] = useState(false);
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();
  const isTablet = useMediaQuery({
    query: '(max-width: 1024px)',
  });

  useEffect(() => {
    const onScroll = () => {
      setVisible(document.documentElement.scrollTop >= 200);
      if (document.documentElement.scrollTop < 200) {
        setIsShown(false);
      }
    };
    onScroll();
    document.addEventListener('scroll', onScroll);
    return () => document.removeEventListener('scroll', onScroll);
  }, []);
  const handleClickTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setIsShown(false);
  };

  return (
    <SectionTemplate>
      <h1>
        <img src={whatIsCling} alt="title" />
      </h1>
      <DiagramBox>
        {i18n.language === 'ko' ? (
          <img src={cvpDiagram} alt="diagram" />
        ) : (
          <img src={cvpDiagramEn} alt="diagram" />
        )}
      </DiagramBox>
      <TextBox>
        <h2>{t('convenience')}</h2>
        <img src={convenience} alt="dots" />
        <p dangerouslySetInnerHTML={{ __html: t('convenience_des') }} />
        <h2>{t('expandability')}</h2>
        <img src={expandability} alt="dots" />
        <p dangerouslySetInnerHTML={{ __html: t('expandability_des') }} />
        <h2>{t('reliability')}</h2>
        <img src={reliability} alt="dots" />
        <p dangerouslySetInnerHTML={{ __html: t('reliability_des') }} />
      </TextBox>
      {visible && (
        <IconBox>
          {!isTablet && (
            <PlusWrap>
              <Control
                isShown={isShown}
                onClick={() => setIsShown((prev) => !prev)}
              />

              <ShowIcon isShown={isShown}>
                <li onClick={() => window.open('https://clingswap.io/')}>
                  <img src={clingSwap} alt="sns" />
                </li>
                <li
                  onClick={() =>
                    window.open('https://discord.gg/carrieverseofficial')
                  }
                >
                  <img src={iconDiscord} alt="sns" />
                </li>
                <li onClick={() => window.open('https://t.me/CarrieVerse')}>
                  <img src={iconTelegram} alt="sns" />
                </li>
                <li
                  onClick={() => window.open('https://twitter.com/CarrieVerse')}
                >
                  <img src={iconTwitter} alt="sns" />
                </li>
                <li
                  onClick={() => window.open('https://medium.com/carrieverse')}
                >
                  <img src={iconMedium} alt="sns" />
                </li>
              </ShowIcon>
            </PlusWrap>
          )}

          <BtnTop onClick={handleClickTop}></BtnTop>
        </IconBox>
      )}
      <Division src={wave1} alt="division" />
    </SectionTemplate>
  );
};
export default WhatIsCling;

export const SectionTemplate = styled.section`
  text-align: center;
  position: relative;
  padding-bottom: 84px;
  overflow: hidden;

  h1 {
    padding-top: 100px;
  }

  @media ${({ theme }) => theme.mq.tablet} {
    padding-bottom: 80px;
    h1 > img {
      height: 75px;
    }
    h1 {
      padding-top: 90px;
    }
  }

  @media ${({ theme }) => theme.mq.mobile} {
    padding-bottom: 64px;
    h1 > img {
      height: 50px;
    }
    h1 {
      padding-top: 60px;
    }
  }
`;

const DiagramBox = styled.div`
  background-color: #fafaff;
  margin: 80px auto;
  width: 918px;
  @media ${({ theme }) => theme.mq.tablet} {
    width: 662px;
    img {
      height: 400px;
    }
  }
  @media ${({ theme }) => theme.mq.mobile} {
    margin-top: 60px;
    width: 100%;
    img {
      height: 266px;
    }
  }
`;

const TextBox = styled.div`
  max-width: 480px;
  margin: 40px auto 50px auto;
  h2 {
    font-size: 3rem;
    font-family: SpoqaBold;
  }
  p {
    font-family: SpoqaMedium;
    font-weight: 500;
    font-size: 2rem;
    margin-bottom: 60px;
    line-height: 34px;
  }
  img {
    margin: 8px 0;
  }

  @media ${({ theme }) => theme.mq.tablet} {
    h2 {
      font-size: 26px;
    }
  }

  @media ${({ theme }) => theme.mq.mobile} {
    max-width: 280px;
    p {
      font-weight: 500;
      line-height: 20px;
      margin-bottom: 20px;
    }
    img {
      height: 4px;
      margin: 4px 0;
    }
  }
`;
const BtnTop = styled.button`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-image: url(${btnTop});
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #a699ff;
  transition: all 0.3s ease;
  cursor: pointer;
  &:hover {
    background-color: #5b48d9;
  }
  @media ${({ theme }) => theme.mq.mobile} {
    width: 8.8889vw;
    height: 8.8889vw;
    background-size: 5vw;
  }
`;
const IconBox = styled.div`
  ${({ theme }) => theme.positions.flexCenterXY};
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  gap: 40px;
  bottom: 55px;
  right: 10%;
  z-index: 10;
  @media ${({ theme }) => theme.mq.tablet} {
    bottom: 60px;
    right: 80px;
  }
  @media ${({ theme }) => theme.mq.mobile} {
    bottom: 11.1111vw;
    right: 5.5556vw;
  }
`;

const Control = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  width: 64px;
  height: 64px;
  background-image: url(${({ isShown }) => (isShown ? btnClose : btnPlus)});
  background-repeat: no-repeat;
  background-position: center center;
  background-color: ${({ isShown }) => (isShown ? '#302099' : '#A699FF')};
  border-radius: 50%;
  transition: background-color 0.3s ease;
  z-index: 1000;
  &:hover {
    background-color: ${({ isShown }) => (isShown ? '#302099' : '#5B48D9')};
  }
`;

const Division = styled.img`
  position: absolute;
  left: calc(50% - 960px);
  bottom: -680px;
  @media ${({ theme }) => theme.mq.tablet} {
    transform: scale(0.55);
    bottom: -645px;
  }
  @media ${({ theme }) => theme.mq.mobile} {
    transform: scale(0.36);
    bottom: -617px;
  }
`;

const PlusWrap = styled.div`
  position: relative;
  width: 64px;
  height: 64px;
`;
const ShowIcon = styled.ul`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  li {
    ${({ theme }) => theme.positions.flexCenterXY};
    position: absolute;
    width: 40px;
    height: 40px;
    background-color: #a699ff;
    border-radius: 50%;
    transition: all 0.3s ease;
    opacity: ${({ isShown }) => (isShown ? '1' : '0')};
    cursor: pointer;
    &:hover {
      background-color: #5b48d9;
    }
    &:first-child {
      left: ${({ isShown }) => (isShown ? '-69px' : '0')};
      top: ${({ isShown }) => (isShown ? '32px' : '0')};
    }
    &:nth-child(2) {
      left: ${({ isShown }) => (isShown ? '-61px' : '0')};
      top: ${({ isShown }) => (isShown ? '-32px' : '0')};
    }
    &:nth-child(3) {
      left: ${({ isShown }) => (isShown ? '-8px' : '0')};
      top: ${({ isShown }) => (isShown ? '-69px' : '0')};
    }
    &:nth-child(4) {
      left: ${({ isShown }) => (isShown ? '56px' : '0')};
      top: ${({ isShown }) => (isShown ? '-61px' : '0')};
    }
    &:last-child {
      left: ${({ isShown }) => (isShown ? '93px' : '0')};
      top: ${({ isShown }) => (isShown ? '-10px' : '0')};
    }
  }
`;
