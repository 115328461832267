import Cling from "./cling";
import Wallet from "./wallet";
import NFT from "./nft";
import Roadmap from "./roadmap";
import PressRelease from "./PressRelease";
import Partner from "./partner";
import {pageNumberStore} from "../../store/admin";
import {useEffect} from "react";
import {useMediaQuery} from "react-responsive";
import ClingSwapEventPopup from "../../popups/ClingSwapEventPopup";

const Home = () => {
    const {pageNumber, setPageNumber} = pageNumberStore();

    const isMobile = useMediaQuery({
        query: "(max-width: 600px)",
    });

    const isTablet = useMediaQuery({
        query: "(max-width: 1300px)",
    });

    useEffect(() => {
        if (pageNumber) {
            const element = document.getElementById(pageNumber);
            if (isMobile) {
                window.scrollTo(0, element.offsetTop - 80);
            } else {
                window.scrollTo(0, element.offsetTop - 100);
            }
            setPageNumber(null);
        }
        setPageNumber(null);
    }, [pageNumber, isMobile]);

    return (
        <>
            {/*<ClingSwapEventPopup/>*/}
            <Cling/>
            <Wallet/>
            <NFT/>
            <Roadmap/>
            <PressRelease/>
            <Partner isMobile={isMobile} isTablet={isTablet}/>
        </>
    );
};

export default Home;
