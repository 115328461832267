import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import { Link } from 'react-scroll';
import styled from 'styled-components';
import { MENU } from '../../utills/staticData';
import {
  clingLogo,
  clingSwapM,
  iconDiscordM,
  iconMediumM,
  iconTelegramM,
  iconTwitterM,
  xMark,
} from '../../assets/img';
import { clickHandler } from '../../utills/common';
import { useTranslation } from 'react-i18next';
import useNav from '../../hooks/useNav';

const DrawerComponent = ({ isOpen, setIsOpen }) => {
  const { i18n } = useTranslation();
  const { goToClickedNumber } = useNav();

  const closeDrawer = () => {
    setIsOpen(false);
  };

  const switchAndClose = (lang) => {
    clickHandler(lang);
    closeDrawer();
  };

  const handleMenu = (key) => {
    closeDrawer();
    goToClickedNumber(key);
  };

  return (
    <DrawerStyle
      size="100vw"
      direction="right"
      open={isOpen}
      className="drawer"
    >
      <FirstLine>
        <Link to="1" onClick={closeDrawer}>
          <Logo src={clingLogo} />
        </Link>
        <IconBox lang={i18n.language}>
          <div>
            <button onClick={() => switchAndClose('ko')}>KOR</button>
            <span>|</span>
            <button onClick={() => switchAndClose('en')}>ENG</button>
          </div>
          <button onClick={closeDrawer}>
            <img src={xMark} alt="x" />
          </button>
        </IconBox>
      </FirstLine>
      <ul className="menuList">
        {MENU.map((item) => (
          <Link
            offset={-80}
            key={item.key}
            to={item.key}
            spy={true}
            smooth={true}
            onClick={() => handleMenu(item.key)}
          >
            <li>{item.name}</li>
          </Link>
        ))}
      </ul>
      <IconWrap>
        <li>
          <a href="https://clingswap.io/" target="blank">
            <img src={clingSwapM} alt="" />
          </a>
        </li>
        <li>
          <a href="https://discord.gg/carrieverseofficial" target="blank">
            <img src={iconDiscordM} alt="" />
          </a>
        </li>
        <li>
          <a href="https://clingswap.io/" target="blank">
            <img src={iconTelegramM} alt="" />
          </a>
        </li>
        <li>
          <a href="https://twitter.com/CarrieVerse" target="blank">
            <img src={iconTwitterM} alt="" />
          </a>
        </li>
        <li>
          <a href="https://medium.com/carrieverse" target="blank">
            <img src={iconMediumM} alt="" />
          </a>
        </li>
      </IconWrap>
    </DrawerStyle>
  );
};
export default DrawerComponent;

const DrawerStyle = styled(Drawer)`
  &.drawer {
    background: ${({ theme }) => theme.colors.main};
    color: white;
  }
  .menuList {
    ${({ theme }) => theme.positions.flexColumnY};
    justify-content: center;
    height: 74%;
    li {
      font-family: Poppin400, sans-serif;
      font-size: 3rem;
      margin-bottom: 48px;
      cursor: pointer;
      :hover {
        font-family: Poppin700, sans-serif;
      }
    }
  }
`;

const FirstLine = styled.div`
  ${({ theme }) => theme.positions.spaceBetween};
  align-items: center;
  height: 100px;
  padding: 0 24px;

  @media ${({ theme }) => theme.mq.mobile} {
    height: 80px;
  }
`;

const IconBox = styled.div`
  display: flex;
  align-items: center;
  div {
    margin-right: 13px;
    button,
    span {
      font-size: 18px;
    }
    button {
      :first-of-type {
        font-family: ${({ lang }) => (lang === 'ko' ? 'Poppin700' : '')};
      }
      :last-of-type {
        font-family: ${({ lang }) => (lang === 'en' ? 'Poppin700' : '')};
      }
    }
  }
  img {
    width: 40px;
  }

  @media ${({ theme }) => theme.mq.mobile} {
    img {
      width: 26px;
    }
  }
`;

const Logo = styled.img`
  height: 46px;
  @media ${({ theme }) => theme.mq.mobile} {
    height: 30px;
  }
`;

const IconWrap = styled.ul`
  position: fixed;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  width: 100%;
  li {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background-color: #fff;
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }
  }
  @media ${({ theme }) => theme.mq.mobile} {
    gap: 20px;
    bottom: 40px;
    li {
      width: 8.8889vw;
      height: 8.8889vw;
      a {
        img {
          width: 7.2222vw;
        }
      }
    }
  }
`;
