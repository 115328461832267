import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "../lib/fbase";

export const fetchBoardData = async () => {
  const snapshot = await getDocs(
    query(collection(db, "clingBoard"), orderBy("date", "desc"))
  );
  return snapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
};

export const fetchClingPartnershipsData = async () => {
  const snapshot = await getDocs(
      query(collection(db, "clingPartnerships"), orderBy("date", "desc"))
  );
  const listArr = snapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  return listArr;
};


export const fetchClingInvestorsData = async () => {
  const snapshot = await getDocs(
      query(collection(db, "clingInvestors"), orderBy("date", "desc"))
  );
  const listArr = snapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  return listArr;
};
