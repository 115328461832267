import FirstSection from "./FirstSection";
import WhatIsCling from "./WhatIsCling";
import ClingFeature from "./ClingFeature";
import AboutCVTX from "./AboutCVTX";

const Cling = () => {
  return (
    <>
      <FirstSection />
      <WhatIsCling />
      <ClingFeature />
      <AboutCVTX />
    </>
  );
};
export default Cling;
