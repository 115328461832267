const fontSizes = {
  mainTitle: "3.2rem",
  menu: "2.4rem",
  subTitle: "2.0rem",
};

const colors = {
  main: "#5B48D9",
  point: "#302099",
  mainText: "#FFFFFF",
  mainBg: "#F5F3FF",
  secondaryBg: "#FBFBFF",
};

const positions = {
  flexCenterXY: "display: flex; justify-content: center; align-items: center;",
  flexCenterX: "display: flex; justify-content: center;",
  flexCenterY: "display: flex; align-items: center;",
  flexColumnY: "display: flex; flex-direction: column; align-items: center;",
  spaceBetween: "display: flex; justify-content: space-between;",
  spaceAround: "display: flex; justify-content: space-around;",
};

const mq = {
  mobile: "(max-width: 600px)",
  tablet: "(max-width: 1024px)",
  desktop: "(max-width: 1920px)",
  header: "(max-width: 1300px)",
};
export const theme = {
  fontSizes,
  colors,
  positions,
  mq,
};
