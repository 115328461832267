import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import langEn from "./lang.en";
import langKo from "./lang.ko";

const resources = {
  en: {
    translation: langEn,
  },
  ko: {
    translation: langKo,
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    //초기 설정 언어
    lng: "ko",
    fallbackLng: "ko",
    debug: false,
    defaultNS: "translation",
    ns: "translation",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  })
  .then((r) => r);
