import {
  eco,
  glass1,
  glass2,
  governance,
  polygon,
  tokenSymbol,
} from "../assets/img";

export const MENU = [
  { name: "Cling", key: 1 },
  { name: "Wallet", key: 2 },
  { name: "NFT", key: 3 },
  { name: "Roadmap", key: 4 },
  { name: "Media", key: 5 },
  { name: "Partnership", key: 6 },
];

export const CARRIE_DESCRIPTION = [
  {
    name: "ㆍ 플랫폼 명",
    name_en: "ㆍName of Platform",
    answer: "캐리버스(Carrieverse)",
    answer_en: " Carrieverse",
  },
  {
    name: "ㆍ 장르",
    name_en: "ㆍGenre",
    answer: "게임형 메타버스(Game Metaverse)",
    answer_en: " Gamified Metaverse",
  },
  {
    name: "ㆍ 플랫폼",
    name_en: "ㆍPlatform",
    answer: "PC, App(Android, ios)",
    answer_en: "PC, App(Android, ios)",
  },
  {
    name: "ㆍ 출시일정",
    name_en: "ㆍLaunch",
    answer: "정식 서비스 중",
    answer_en: "Officially in service",
  },
  {
    name: "ㆍ Media Channel",
    name_en: "ㆍMedia Channel",
    answer: "YouTube",
    answer_en: " YouTube",
  },
];

export const KOLA_DESCRIPTION = [
  {
    name: "ㆍ 플랫폼 명",
    name_en: "ㆍName of Platform",
    answer: "슈퍼콜라 (Super Kola)",
    answer_en: " Super Kola",
  },
  {
    name: "ㆍ 장르",
    name_en: "ㆍGenre",
    answer: "전략 TCG (Strategy Trading Card Game)",
    answer_en: "P2E Strategy Card Game",
  },
  {
    name: "ㆍ 플랫폼",
    name_en: "ㆍPlatform",
    answer: "PC, App(Android, ios)",
    answer_en: "PC, App(Android, ios)",
  },
  {
    name: "ㆍ 출시일정",
    name_en: "ㆍLaunch",
    answer: "2024 2Q 예정",
    answer_en: "2024 2Q (Estimated)",
  },
  {
    name: "ㆍ Media Channel",
    name_en: "ㆍMedia Channel",
    answer: "YouTube",
    answer_en: " YouTube",
  },
];

export const CARD_DATA = [
  {
    src: tokenSymbol,
    title: "CVTX",
    list_en: [
      "ㆍGovernance Token",
      "ㆍHolders can contribute within the ecosystem",
      "Own and Transfer",
      "Exchange(Swap) on blockchain",
    ],
    list: [
      "ㆍ생태계 최상위 토큰",
      "ㆍ홀더들의 생태계 운영참여",
      "ㆍ자유로운 보유 및 이동",
      "ㆍ블록체인을 통한 교환",
    ],
  },
  {
    src: governance,
    title: "거버넌스 토큰",
    title_en: "Key Features",
    list_en: [
      "ㆍMake proposals",
      "ㆍAccess to decision-making process",
      "ㆍDelegated authority",
      "ㆍConnect utility tokens(sub tokens)",
    ],
    list: [
      "ㆍ게임 플랫폼의 아젠다 형성",
      "ㆍ형성된 아젠다의 결정",
      "ㆍ주요 참여자의 권리 대변",
      "ㆍ각 컨텐츠의 유틸리티 <br/>토큰(서브 토큰)과 연결",
    ],
  },
  {
    src: polygon,
    title: "폴리곤(메틱)",
    title_en: "Polygon",
    list_en: [
      "ㆍETH compatible mainnet",
      "ㆍCompatible and Scalable",
      "ㆍScale-up of CVTX ecosystem with strategic partnership",
    ],
    list: [
      "ㆍ글로벌 메인넷 노드",
      "ㆍ범용성과 시장성",
      "ㆍ제3자 파트너쉽을 통해 꾸준한<br/>CVTX 생태계 확장",
    ],
  },
  {
    src: eco,
    title: "에코시스템",
    title_en: "Ecosystem",
    list_en: [
      "ㆍPublication of CVTX public node",
      "ㆍEnsure transparent operation and circulation of utility token",
      "ㆍEcosystem of utility tokens and special assets usages",
    ],
    list: [
      "ㆍCVTX 퍼블릭노드 발행·배포",
      "ㆍ유틸리티 토큰이 동일 계층에 <br/>제대로 된 유통과 운영 확보",
      "ㆍ유틸리티 토큰과 특수 재화의 순환 생태계 보장 ",
    ],
  },
];

export const FEATURE_DATA = (isMobile) => {
  return [
    {
      position: "left",
      h1: "W",
      src: glass1,
      p: "Web 3.0",
      h3: "Web 3.0: 블록체인 플랫폼의 시대",
      h3_en: "Age of Decentralized Network",
      list: [
        "분산(Decentralization)",
        "소유(Ownership)",
        "자치(Autonomy)",
        "독립(Independence)",
      ],
      list_en: ["Decentralization", "Ownership", "Autonomy", "Independence"],
    },
    {
      position: "right",
      h1: "X",
      src: isMobile ? glass2 : glass1,
      p: "eXperience",
      h3: "TX(Total Experience; 총체적 경험)",
      h3_en: "TX(Total Experience)",
      list: [
        "UX(User’s Experience: 유저 경험)",
        "+CX(Customer Experience: 고객경험)",
        "+MX(Multi-Experience: 다중경험)",
        "+EX(Employee Experience: 직원경험)",
      ],
      list_en: [
        "UX(User’s Experience)",
        "+CX(Customer Experience)",
        "+MX(Multi-Experience)",
        "+EX(Employee Experience)",
      ],
    },
    {
      position: "left",
      h1: "Y",
      src: glass1,
      p: "whY?",
      h3: "왜 블록체인과 메타버스?",
      h3_en: "Why Blockchain and Metaverse?",
      list: [
        "인프라(Infra)+플랫폼(Platfrom)+",
        "콘텐츠(Contents)+IP로 구성된",
        "생태계 공간을 마련해 탈 중앙화 된",
        "기술 구조와의 시너지 기대",
      ],
      list_en: [
        "The ecosystem with",
        "Infrastructure+Platform+Contents+IP",
        "can make synergy within",
        "the decentralized network",
      ],
    },
    {
      position: "right",
      h1: "Z",
      src: isMobile ? glass2 : glass1,
      p: "Z to a",
      h3: "MZ세대 부터 알파세대 까지",
      h3_en: "From MZ Gen To α Gen",
      list: [" 가족 구성원 모두를 위한", "패밀리 플랫폼"],
      list_en: ["Interactive platform", "with Family-friendly contents"],
    },
  ];
};
