import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { googleLogo } from "../../../assets/img";
import { auth, loginInGoogle } from "../../../lib/fbase";
import { tokenStore, userStore } from "../../../store/admin";

const Login = () => {
  const { checkAdmin, setUserUid } = userStore();
  const { setIsLogged } = tokenStore();
  const navigate = useNavigate();

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        const res = await checkAdmin(user.uid);
        const getToken = await user.getIdToken();
        setIsLogged(getToken);
        if (res) {
          navigate("/clingAdmin/list");
          setUserUid(user.uid);
        } else {
          navigate("/clingAdmin");
          setUserUid(null);
        }
      }
    });
  }, []);

  return (
    <LoginWrapper>
      <Button onClick={loginInGoogle}>
        <img width={30} src={googleLogo} alt="google" />
        <span>구글 로그인</span>
      </Button>
    </LoginWrapper>
  );
};
export default Login;

const LoginWrapper = styled.div`
  height: 100vh;
  background: ${({ theme }) => theme.colors.mainBg};
  display: flex;
`;

const Button = styled.button`
  margin: auto;
  width: 250px;
  height: 50px;
  background: ${({ theme }) => theme.colors.main};
  ${({ theme }) => theme.positions.flexCenterY};
  justify-content: space-evenly;
  span {
    color: white;
    font-size: 16px;
  }
`;
