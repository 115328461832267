import styled from 'styled-components';
import {
  wave3,
  walletTitle,
  phone,
  phoneEn,
  clingToEarn,
  graph,
  dao,
  daoImg,
  glass3,
} from '../../../assets/img';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const Wallet = () => {
  const { t } = useTranslation();

  return (
    <WalletSection id="2">
      <Division src={wave3} alt="division" />
      <TitleBox>
        <img src={walletTitle} alt="title" />
      </TitleBox>
      {i18n.language === 'ko' ? (
        <Phone src={phone} alt="phone" />
      ) : (
        <Phone src={phoneEn} alt="phone" />
      )}
      <TextBox>
        <h1>{t('convenience')}</h1>
        <p>
          <b>{t('wallet_b')}</b>
          {t('wallet_p')}
        </p>
        <h1>{t('versatility')}</h1>
        <p>
          <b>{t('versatility_b')}</b>
          {t('versatility_p')}
        </p>
        <h1>{t('security')}</h1>
        <p>
          <b>{t('security_b')}</b>
          <p dangerouslySetInnerHTML={{ __html: t('security_p') }} />
        </p>
        <h1>{t('speed')}</h1>
        <p>
          <b>{t('speed_b')}</b>
          <p dangerouslySetInnerHTML={{ __html: t('speed_p') }} />
        </p>
      </TextBox>
      <Part>
        <h2>
          <img src={clingToEarn} alt="title" />
        </h2>
        <img src={graph} alt="graph" />
        <TextBox>
          <h1>Swap</h1>
          <p> {t('swap')}</p>
          <h1>
            Staking<span>(Single Staking, NFT Staking)</span>
          </h1>
          <p>{t('staking')}</p>
          <h1>
            Yield Farming<span>(Pair ·LP Staking)</span>
          </h1>
          <p>{t('yieldFarming')}</p>
        </TextBox>
        <a href="https://clingswap.io/" target="blank">
          Cling Swap 바로가기
        </a>
      </Part>
      <Part2>
        <h2>
          <img src={dao} alt="title" />
        </h2>
        <img src={daoImg} alt="img" />
        <TextBox>
          <h1>{t('diversity')}</h1>
          <p>{t('diversity_p')}</p>
          <h1>{t('autonomy')}</h1>
          <p>{t('autonomy_p')}</p>
          <h1>{t('breakaway')}</h1>
          <p>{t('breakaway_p')}</p>
        </TextBox>
      </Part2>
      <Division2 src={glass3} alt="division" />
    </WalletSection>
  );
};
export default Wallet;

const WalletSection = styled.section`
  position: relative;
  text-align: center;
  overflow: hidden;
`;

const TitleBox = styled.div`
  background: #413f52;
  width: 528px;
  height: 278px;
  line-height: 278px;
  display: block;
  margin: 0 auto 83px;
  position: inherit;
  img {
    vertical-align: middle;
  }

  @media ${({ theme }) => theme.mq.tablet} {
    width: 420px;
    img {
      height: 160px;
    }
  }
  @media ${({ theme }) => theme.mq.mobile} {
    width: 217px;
    height: 140px;
    line-height: 140px;

    img {
      height: 100px;
    }
  }
`;
const Division = styled.img`
  position: absolute;
  top: -1550px;
  left: calc(50% - 960px);
  @media ${({ theme }) => theme.mq.tablet} {
    transform: scale(0.55);
    top: -1600px;
  }
  @media ${({ theme }) => theme.mq.mobile} {
    transform: scale(0.2);
    top: -1639px;
  }
`;

const Phone = styled.img`
  width: 651px;
  position: inherit;
  @media ${({ theme }) => theme.mq.tablet} {
    width: 580px;
  }
  @media ${({ theme }) => theme.mq.mobile} {
    width: 304px;
  }
`;

export const TextBox = styled.div`
  width: 728px;
  margin: 80px auto 0;
  text-align: left;
  position: inherit;
  z-index: 2;
  h1 {
    color: ${({ theme }) => theme.colors.main};
    font-family: SpoqaMedium, sans-serif;
    font-weight: bold;
    font-size: 3rem;
    margin-bottom: 16px;
    span {
      font-size: 2.2rem;
      font-weight: 500;
    }
  }
  p {
    word-break: keep-all;
    font-size: 2.4rem;
    margin-bottom: 60px;
    line-height: 40px;
    b {
      display: block;
      font-size: 2.6rem;

      font-family: SpoqaBold, Poppin700, sans-serif;
    }
  }

  @media ${({ theme }) => theme.mq.tablet} {
    width: 460px;
    margin: 50px auto 0;
  }

  @media ${({ theme }) => theme.mq.mobile} {
    width: 262px;
    h1 {
      margin-bottom: 6px;
    }
    p {
      margin-bottom: 40px;
      line-height: 24px;
      b {
      }
    }
  }
`;

const Part = styled.section`
  background-color: ${({ theme }) => theme.colors.secondaryBg};
  h2 {
    padding-top: 100px;
    margin-bottom: 80px;
    img {
      height: 85px;
    }
  }
  img {
    height: 645px;
  }
  a {
    display: inline-block;
    padding: 28px 30px;
    background-color: #5b48d9;
    margin-bottom: 80px;
    border-radius: 30px;
    font-size: 28px;
    font-weight: 700;
  }

  @media ${({ theme }) => theme.mq.tablet} {
    h2 {
      padding-top: 80px;
      img {
        height: 70px;
      }
    }
    img {
      height: 346px;
    }
    a {
      padding: 24px 26px;
    }
  }

  @media ${({ theme }) => theme.mq.mobile} {
    h2 {
      padding-top: 25px;
      margin-bottom: 50px;
      img {
        height: 50px;
      }
    }
    img {
      height: 174px;
    }
    a {
      margin-bottom: 25px;
      padding: 14px 16px;
      font-size: 14px;
      border-radius: 14px;
    }
  }
`;

const Part2 = styled(Part)`
  padding-bottom: 80px;
  background-color: ${({ theme }) => theme.colors.mainText};
  h2 {
    img {
      height: 127px;
    }
  }
  img {
    height: 402px;
  }

  @media ${({ theme }) => theme.mq.tablet} {
    h2 {
      img {
        height: 100px;
      }
    }
    img {
      height: 220px;
    }
  }

  @media ${({ theme }) => theme.mq.mobile} {
    h2 {
      img {
        height: 70px;
      }
    }
    img {
      height: 137px;
      margin-bottom: 0;
    }
  }
`;

const Division2 = styled.img`
  position: absolute;
  bottom: -214px;
  left: 0;
  width: 400px;
  @media ${({ theme }) => theme.mq.tablet} {
    width: 300px;
    bottom: -125px;
  }
  @media ${({ theme }) => theme.mq.mobile} {
    width: 128px;
    bottom: -73px;
  }
`;
