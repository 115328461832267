import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { MENU } from '../../utills/staticData';
import { clingLogo, toggleBtn } from '../../assets/img';
import { useState } from 'react';
import DrawerComponent from './DrawerComponent';
import { Link } from 'react-scroll';
import List from './List';
import { clickHandler } from '../../utills/common';
import { useLocation } from 'react-router-dom';
import useNav from '../../hooks/useNav';
import { useMediaQuery } from 'react-responsive';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState(0);
  const location = useLocation();
  const { i18n } = useTranslation();
  const { goToClickedNumber } = useNav();
  const isTablet = useMediaQuery({
    query: '(max-width: 1024px)',
  });

  const needToHide =
    location.pathname === '/clingAdmin' ||
    location.pathname === '/clingAdmin/list' ||
    location.pathname === '/clingAdmin/edit';

  if (needToHide) return null;
  return (
    <>
      <NavStyle lang={i18n.language}>
        <Link
          to="1"
          smooth={true}
          onClick={() => {
            setSelectedMenu(0);
            goToClickedNumber(1);
          }}
        >
          <div>
            <img src={clingLogo} alt="logo" />
          </div>
        </Link>
        <RightSide>
          <ul>
            {MENU.map((item, idx) => (
              <List
                key={idx}
                item={item}
                idx={idx}
                setSelectedMenu={setSelectedMenu}
                selectedMenu={selectedMenu}
              />
            ))}
          </ul>
          <div
            onClick={() => {
              if (i18n.language === 'en') {
                window.open(
                  'https://business-20.gitbook.io/cling-whitepaper-eng/'
                );
              } else {
                window.open('https://business-20.gitbook.io/cling-whitepaper/');
              }
            }}
          >
            Whitepaper
          </div>
          <span>
            <span onClick={() => clickHandler('ko')}>KOR</span>|
            <span onClick={() => clickHandler('en')}>ENG</span>
          </span>
          <button onClick={() => setIsOpen((prev) => !prev)}>
            <img src={toggleBtn} alt="toggle" />
          </button>
        </RightSide>
      </NavStyle>
      {isTablet && <DrawerComponent isOpen={isOpen} setIsOpen={setIsOpen} />}
    </>
  );
};
export default Header;

const NavStyle = styled.nav`
  position: fixed;
  top: 0;
  ${({ theme }) => theme.positions.spaceBetween};
  align-items: center;
  width: 100%;
  height: 100px;
  padding: 0 60px;
  background: ${({ theme }) => theme.colors.main};
  z-index: 90;

  div {
    cursor: pointer;

    img {
      width: 140px;
    }
  }

  span {
    font-size: 18px;
    color: white;
    height: 32px;
    width: 134px;

    span {
      cursor: pointer;

      :hover {
        font-family: Poppin700, sans-serif;
      }

      :first-of-type {
        font-family: ${({ lang }) => (lang === 'ko' ? 'Poppin700' : '')};
        margin-left: 44px;
        margin-right: 5px;
      }

      :last-of-type {
        margin-left: 5px;
        font-family: ${({ lang }) => (lang === 'en' ? 'Poppin700' : '')};
      }
    }
  }

  ul {
    display: flex;
    font-size: 18px;
    color: #ffff;
    font-family: Poppin500, sans-serif;

    li {
      height: 30px;
    }
  }

  button {
    display: none;
  }

  @media ${({ theme }) => theme.mq.header} {
    ul {
      display: none;
    }

    button {
      display: block;

      img {
        width: 40px;
      }
    }

    span {
      display: none;
    }
  }
  @media ${({ theme }) => theme.mq.mobile} {
    height: 80px;
    padding: 0 24px;
    div {
      img {
        width: 92px;
      }
    }

    button {
      img {
        width: 26px;
      }
    }
  }
`;

const RightSide = styled.div`
  display: flex;
  align-items: center;

  div {
    color: ${({ theme }) => theme.colors.mainText};
    background: ${({ theme }) => theme.colors.point};
    border-radius: 20px;
    font-size: 18px;
    text-align: center;
    padding: 10px 27px;
    font-family: Poppin400, sans-serif;
    cursor: pointer;
  }

  @media ${({ theme }) => theme.mq.header} {
    div {
      margin-right: 10px;
    }
  }
  @media ${({ theme }) => theme.mq.mobile} {
    div {
      font-size: 12px;
      margin-right: 4px;
      padding: 9px 14px;
    }
  }
`;
