import { ko } from "date-fns/esm/locale";
import { MyContainer } from "./SubComponent";
import styled from "styled-components";
import DatePicker from "react-datepicker";

const Contents = ({
  boardContent,
  setBoardContent,
  getValue,
  handleThumbnailChange,
}) => {
  return (
    <>
      <StyledDatePicker
        locale={ko}
        dateFormat="yyyy.MM.dd"
        selected={boardContent.date}
        onChange={(date) => {
          setBoardContent((prev) => ({ ...prev, date }));
        }}
        calendarContainer={MyContainer}
      />
      <input
        defaultValue={boardContent.title_ko}
        onChange={getValue}
        name="title_ko"
        type="text"
        placeholder="제목"
        autoComplete="off"
      />
      <textarea
        defaultValue={boardContent.content_ko}
        name="content_ko"
        placeholder="내용"
        onChange={getValue}
      />
      <StyledLabel>
        썸네일 이미지(250*250)
        <input
          onChange={handleThumbnailChange}
          name="thumbnail"
          type="file"
          style={{
            color: "black",
            margin: "0 0 0 20px",
            padding: "0",
            width: "200px",
          }}
        />
      </StyledLabel>
    </>
  );
};

const StyledDatePicker = styled(DatePicker)`
  border-radius: 10px;
  text-align: center;
`;
const StyledLabel = styled.label`
  text-align: left;
  width: fit-content;
`;
export default Contents;
