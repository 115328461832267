import React from 'react';
import styled from "styled-components";
import {event1Img, event1ImgM, iconEventNext} from "../../../assets/img";
import EventTag from "../../../component/EventTag";
import EventBtn from "../../../component/EventBtn";
import {useMediaQuery} from "react-responsive";
import {theme} from "../../../styles/theme";

const FirstEvent = () => {
    const isMobile = useMediaQuery({
        query: "(max-width: 600px)",
    });
    return (
        <FirstEventWrap>
            {isMobile && <NextIcon><img src={iconEventNext} alt=""/></NextIcon>}
            <ContentWrap>
                <EventTag>Event 1</EventTag>
                <TitleWrap>
                    <p>Experience Cling Wallet</p>
                    <h2>Win up to 105 $CVTX!</h2>
                </TitleWrap>
                <ListWrap>
                    <ListContent>
                        <p>Details</p>
                        <ul>
                            <li>Users Who have downloaded Cling Wallet during the <br className={"onlyPc"} />event through Gleam</li>
                        </ul>
                    </ListContent>
                    <ListContent>
                        <p>Prize</p>
                        <ul className={'listStyleDot'}>
                            <li>500 $CVTX to 300 winners (FCFS)</li>
                            <li>1,000 $CVTX to 50 winners through random raffle</li>
                        </ul>
                    </ListContent>
                </ListWrap>
                <EventBtn bgColor={"#5843CC"} link={"https://bit.ly/3EO5KXv"}>Event Link</EventBtn>
            </ContentWrap>
            <ImgWrap><img src={isMobile? event1ImgM : event1Img} alt=""/></ImgWrap>
        </FirstEventWrap>
    );
};

export default FirstEvent;

const FirstEventWrap = styled.div`
  display: flex;
  height: 826px;
  background: #fff;
  width: 1200px;
  margin: 0 auto;
  @media ${theme.mq.mobile}{
    position: relative;
    width: 88.8889vw;
    height: 208.8889vw;
    display: block;
  }
`;
const ContentWrap = styled.div`
  width: 50%;
  padding-top: 120px;
  display: block;
  @media ${theme.mq.mobile}{
    width: 100%;
    padding-top: 11.1111vw;
  }
`;
const TitleWrap = styled.div`
  margin-bottom: 64px;
  font-size: 4.8rem;
  font-family: OneMobileTitle;
  p {
    margin-bottom: 16px;
  }
  h2 {
    display: inline-block;
    padding: 10px;
    color: #fff;
    background-color: #5843CC;
  }
  @media ${theme.mq.mobile}{
    margin-bottom: 69.4444vw;
    font-size: 6.1111vw;
    p {
      margin-bottom: 3.3333vw;
    }
    h2 {
      padding: 2.7778vw;
    }
  }
`;
const ListWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
  margin-bottom: 40px;
  @media ${theme.mq.mobile}{
    gap: 6.6667vw;
    margin-bottom: 11.1111vw;
  }
`;
const ListContent = styled.div`
    p {
      margin-bottom: 12px;
      font-size: 2.0rem;
      font-family: OneMobileTitle;
      font-weight: 400;
    }
    ul {
      li {
        line-height: 1.4;
        font-size: 2.0rem;
        font-family: OneMobile;
      }
      &.listStyleDot {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding-left: 24px;
        list-style: disc;
      }
   }
  @media ${theme.mq.mobile}{
    p {
      margin-bottom: 2.2222vw;
      font-size: 4.1667vw;
    }
    ul {
      li {
        font-size: 4.1667vw;
        &.listStyleDot {
          gap: 0;
          padding-left: 2.7778vw;
        }
      }
    }
  }
`;

const ImgWrap = styled.div`
  width: 50%;
  height: 100%;
  img {
    height: 100%;
  }
  @media ${theme.mq.mobile}{
    position: absolute;
    top: 57.7778vw;
    width: 86.1111vw;
    height: unset;
    img {
      width: 100%;
      height: unset;
    }
  }
`;
const NextIcon  = styled.div`
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  img {
    width: 100%;
  }
`;
