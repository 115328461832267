import create from "zustand";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../lib/fbase";
import { persist } from "zustand/middleware";

export const userStore = create(
  persist((set, get) => ({
    userUid: null,

    setUserUid: (uid) => {
      set({ userUid: uid });
    },

    checkAdmin: async (uid) => {
      const userInfo = doc(db, "users", uid);
      const res = await getDoc(userInfo);
      return res.exists();
    },
  }))
);

export const tokenStore = create(
  persist(
    (set, get) => ({
      isLogged: null,

      setIsLogged: (state) => {
        set({ isLogged: state });
      },
    }),
    { name: "token-storage", getStorage: () => localStorage }
  )
);

export const postStore = (set, get) => ({
  getDetailData: async (id) => {
    const detailRef = doc(db, "clingBoard", id);
    return await getDoc(detailRef);
  },
});

export const pageNumberStore = create((set, get) => ({
  pageNumber: null,
  setPageNumber: (state) => {
    set({ pageNumber: state });
  },
}));
