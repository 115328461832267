import styled from "styled-components";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { StyledEngineProvider } from "@mui/styled-engine";
import "react-datepicker/dist/react-datepicker.css";
import { db, upload } from "../../../lib/fbase";
import { addDoc, collection, doc, updateDoc } from "firebase/firestore";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { postStore } from "../../../store/admin";
import { a11yProps, TabPanel } from "./SubComponent";
import EnglishContent from "./EnglishContent";
import Contents from "./Content";

//TODO 수정시, 이미지 전 파일 기억하기
const Edit = () => {
  const [boardContent, setBoardContent] = useState({
    date: new Date(),
    title_ko: "",
    title_en: "",
    content_ko: "",
    content_en: "",
    thumbnailUrl: "",
  });
  const [photo, setPhoto] = useState(null);
  const [thumbnailPhoto, setThumbnailPhoto] = useState(null);
  const [lanValue, setLanValue] = useState(0);
  const [editingMode, setEditingMode] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const { getDetailData } = postStore();

  const handleChange = (event, newValue) => {
    setLanValue(newValue);
  };
  const getValue = (e) => {
    const { name, value } = e.target;
    setBoardContent({ ...boardContent, [name]: value });
  };
  const handleUploadChange = (e) => {
    if (e.target.files[0]) {
      setPhoto(e.target.files[0]);
    }
  };
  const handleThumbnailChange = (e) => {
    if (e.target.files[0]) {
      setThumbnailPhoto(e.target.files[0]);
    }
  };

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        if (!boardContent.content_ko || !boardContent.title_ko) {
          throw new Error("내용을 입력해주세요.");
        }
        if (!thumbnailPhoto) {
          throw new Error("파일을 선택해주세요.");
        }
      } catch (e) {
        alert(e.message);
      }
      // 최초 등록일 경우
      if (!editingMode) {
        const thumbnailUrl = await upload(thumbnailPhoto);
        const dataObj = {
          date: boardContent.date,
          title_ko: boardContent.title_ko,
          title_en: boardContent.title_en,
          content_ko: boardContent.content_ko,
          content_en: boardContent.content_en,
          thumbnailUrl,
        };
        await addDoc(collection(db, "board"), dataObj);
        navigate("/mantiscoAdmin/list");
      } else {
        // 수정일 경우
        const detailRef = doc(db, "board", id);
        const thumbnailUrl = await upload(thumbnailPhoto);

        await updateDoc(detailRef, {
          date: boardContent.date,
          title_ko: boardContent.title_ko,
          title_en: boardContent.title_en,
          content_ko: boardContent.content_ko,
          content_en: boardContent.content_en,
          thumbnailUrl,
        });
        navigate("/mantiscoAdmin/list");
      }
    },
    [editingMode, boardContent, photo, thumbnailPhoto, navigate, id]
  );

  const checkStoreDetailData = useCallback(async (id) => {
    const res = await getDetailData(id);
    if (res?.exists()) {
      setEditingMode(true);
      let data = res.data();
      data.date = new Date(data.date.seconds * 1000);
      setBoardContent(data);
    } else {
      navigate("/error");
    }
  }, []);

  useEffect(() => {
    // 최초 등록 시 id가 없으므로 실행되지 않게 분기
    if (id !== undefined) checkStoreDetailData(id).then((r) => r);
  }, [checkStoreDetailData, id]);

  return (
    <StyledEngineProvider injectFirst>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <StyledTabs
          orientation="vertical"
          value={lanValue}
          onChange={handleChange}
        >
          <Tab
            sx={{
              color: "black",
              fontSize: "16px",
            }}
            label="한국어"
            {...a11yProps(0)}
          />
          <Tab
            sx={{ color: "black", fontSize: "16px", marginTop: "30px" }}
            label="영어"
            {...a11yProps(1)}
          />
        </StyledTabs>
        <TabPanel value={lanValue} index={0}>
          <StyledForm onSubmit={handleSubmit}>
            <h1>뉴스 작성하기 (한국어)</h1>

            <Contents
              editingMode={editingMode}
              boardContent={boardContent}
              setBoardContent={setBoardContent}
              handleUploadChange={handleUploadChange}
              handleThumbnailChange={handleThumbnailChange}
              getValue={getValue}
            />
            <SubmitBtn type="submit">저장하기</SubmitBtn>
          </StyledForm>
        </TabPanel>
        <TabPanel value={lanValue} index={1}>
          <StyledForm onSubmit={handleSubmit}>
            <h1>뉴스 작성하기 (영어)</h1>
            <EnglishContent
              getValue={getValue}
              boardContent={boardContent}
              editingMode={editingMode}
            />
            <SubmitBtn type="submit">저장하기</SubmitBtn>
          </StyledForm>
        </TabPanel>
      </Box>
    </StyledEngineProvider>
  );
};
const StyledForm = styled.form`
  font-size: 1.6rem;
  margin: 100px auto;
  width: 700px;
  display: flex;
  flex-direction: column;
  text-align: center;
  input {
    margin: 10px 0 30px 0;
    height: 40px;
    padding: 0 10px;
    color: black;
    outline: none;
  }
  textarea {
    color: black;
    outline: none;
    resize: none;
    height: 20vh;
    margin-bottom: 30px;
    padding: 5px 10px;
    line-height: normal;
  }
`;
const SubmitBtn = styled.button`
  height: 50px;
  width: 140px;
  margin: auto;
  background: ${({ theme }) => theme.colors.main};
  border-radius: 10px;
  color: white;
`;
const StyledTabs = styled(Tabs)({
  "& .Mui-selected": {
    color: "#5B48D9",
  },
  "& .MuiTabs-indicator": {
    backgroundColor: "#5B48D9",
  },
});

export default Edit;
