import { v4 } from "uuid";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGIN_ID,
  appId: process.env.REACT_APP_APP_ID,
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const auth = getAuth();
export const provider = new GoogleAuthProvider();

// 파일 Storage
export const storage = getStorage(app);

export const upload = async (photo) => {
  const uri = `images/${v4() + "_" + photo.name}`;
  const fileRef = ref(storage, uri);
  await uploadBytes(fileRef, photo);
  return await getDownloadURL(fileRef);
};

export const loginInGoogle = async () => {
  const result = await signInWithPopup(auth, provider);
  GoogleAuthProvider.credentialFromResult(result);
};
