import React from 'react';
import Header from "../../component/Header";
import {Outlet} from "react-router-dom";
import Footer from "../../component/Footer";

const MainLayout = () => {
    return (
        <>
            <Header />
            <Outlet />
            <Footer />
        </>
    );
};

export default MainLayout;