import React from 'react';
import styled from "styled-components";
import {theme} from "../../styles/theme";

const EventTag = ({children}) => {
    return (
        <StyledSelf>
            {children}
        </StyledSelf>
    );
};

export default EventTag;

const StyledSelf = styled.div`
  display: inline-block;
  padding: 9px 16px;
  margin-bottom: 32px;
  background-color: #333; 
  color: #fff;
  border-radius: 100px;
  font-size: 2.0rem;
  font-family: OneMobileTitle;
  @media ${theme.mq.mobile}{
    padding: 1.8056vw 3.3333vw;
    margin-bottom: 5.5556vw;
    font-size: 3.8889vw;
  }
`;