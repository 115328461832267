import styled from "styled-components";
import {useEffect, useState} from "react";
import {
    popupBg, popupBgM, popupCheck, popupCloseBtn, popupCoinImg
} from "../../assets/img";
import {useMediaQuery} from "react-responsive";
import { Link } from 'react-router-dom';
import {theme} from "../../styles/theme";

const ClingSwapEventPopup = () => {
    // 블링크 현상 막기 위해서 처음에 없다가 나타나게 설정(초기값 false)
    const [showModal, setShowModal] = useState(false);
    const [isDayShowChecked, setIsDayShowChecked] = useState(false);

    const isMobile = useMediaQuery({
        query: "(max-width: 600px)",
    });

    useEffect(() => {
        const HAS_VISITED_BEFORE = localStorage.getItem("hasVisitedBefore");

        const handleShowModal = () => {
            if (HAS_VISITED_BEFORE > new Date()) {
                return;
            }
            setShowModal(true);
        };
        handleShowModal();
    }, []);

    const dayClose = () => {
        let expires = new Date();
        expires = expires.setHours(expires.getHours() + 24);
        localStorage.setItem("hasVisitedBefore", expires);
        setShowModal(false);
    };

    const removeModal = async () => {
        await setShowModal(false);
        const remove = localStorage.removeItem("hasVisitedBefore");
        if (isDayShowChecked) {
            dayClose();
        }
        window.setTimeout(remove, 2000);
    };
    const OnClickDayShowChecked = () => {
        setIsDayShowChecked(!isDayShowChecked);
    };

    return (
        <>
            {showModal && (
                <div
                    style={{
                        position: "fixed",
                        top: 0,
                        bottom: 0,
                        width: "100%",
                        height: "100vh",
                        zIndex: "100",
                        background: isMobile? "rgba(0,0,0,0.6)" : "transparent"
                    }}
                >
                    <PopUpSection>
                        <CloseBtn onClick={removeModal}><img src={popupCloseBtn} alt=""/></CloseBtn>
                        <BackImg src={isMobile? popupBgM : popupBg}/>
                        <PopupContents>
                            <LogoWrapper>
                                <img
                                    src={popupCoinImg}
                                    alt="CVTX image"/>
                            </LogoWrapper>
                            <PopupTitle>
                                <p>Try Cling for Prize Pool</p>
                                <h2>310,000 $CVTX</h2>
                            </PopupTitle>
                            <EventBox>
                                <li>
                                    <EventPopupTag className={"tag"}>Event 1</EventPopupTag>
                                    <p>Download <br className={"onlyPc"}/>Cling Wallet</p>
                                </li>
                                <li>
                                    <EventPopupTag className={"tag"}>Event 2</EventPopupTag>
                                    <p>Stake your $CVTX <br/>on ClingSwap</p>
                                </li>
                            </EventBox>
                            <ButtonWrapper>
                                <Link className={"eventBtn"} to={"/event"}>Event Detail</Link>
                                <DayShowCheckArea onClick={() => {
                                    OnClickDayShowChecked()
                                }}>
                                    <input type={"checkbox"} checked={isDayShowChecked}/>
                                    <div>Do Not Show For 24 Hours</div>
                                </DayShowCheckArea>
                            </ButtonWrapper>
                        </PopupContents>


                    </PopUpSection>
                </div>
            )}
        </>
    );
};

const PopUpSection = styled.section`
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 40px 30px 30px;
  width: 520px;
  height: 644px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #5843CC;
  border-radius: 20px;
  border-top: 2px solid rgba(255, 255, 255, 0.60);
  border-bottom: 2px solid rgba(255, 255, 255, 0.60);
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
  z-index: 100;
  overflow: hidden;
  
  @media ${theme.mq.mobile} {
    padding: 12.2222vw 5.5556vw 6.6667vw;
    width: 91.1111vw;
    height: 155.2778vw;
    border-top: none;
    border-bottom: none;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
  }
`;

const BackImg = styled.img`
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

const PopupTitle = styled.div`
  font-style: normal;
  line-height: normal;
  text-align: center;
  color: #fff;
  font-family: OneMobileTitle;
  p {
    margin-bottom: 16px;
    font-size: 2.4rem;
  }
  h2 {
    font-size: 3.6rem;
  }
  @media ${theme.mq.mobile} {
    margin-bottom: 11.6667vw;
    p {
      margin-bottom: 2.7778vw;
      font-size: 5.5556vw;
    }
    h2 {
      font-size: 8.3333vw;
    }
  }
`;

const EventBox = styled.ul`
  display: flex;
  align-items: flex-start;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.30);
  background: rgba(255, 255, 255, 0.10);
  backdrop-filter: blur(10px);
  padding: 36px 0 44px;
  width: 460px;
  height: 180px;
  text-align: center;
  li:first-child {
    position: relative;
    width: 220px;
    &::after {
      display: block;
      position: absolute;
      content: "";
      width: 1px;
      height: 24px;
      left: 100%;
      top: 61px;
      background: rgba(255, 255, 255, 0.60);
    }
  }
  li:last-child {
    width: 240px;
  }
  p {
    color: #fff;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
    font-size: 2.0rem;
    font-family: OneMobileTitle;
    line-height: 1.2;
  }
  @media ${theme.mq.mobile}{
    flex-direction: column;
    gap: 7.2222vw;
    border: none;
    background: none;
    backdrop-filter: none;
    padding: 0;
    width: 100%;
    height: unset;
    margin-bottom: 7.7778vw;
    li {
      position: relative;
      padding: 6.6667vw 0 6.1111vw;
      border-radius: 5.5556vw;
      border: 1px solid rgba(255, 255, 255, 0.30);
      background: rgba(255, 255, 255, 0.10);
      backdrop-filter: blur(10px);
      .tag {
        position: absolute;
        top: -3.8889vw;
        left: 50%;
        transform: translateX(-50%);
        padding: 2.0833vw 3.3333vw;
        font-size: 3.3333vw;
      }
      &:first-child {
        width: 100%;
        &::after{
          display: none;
        }
      }
      &:last-child {
        width: 100%;
      }
    }
    p {
      font-size: 4.4444vw;
    }
  }
`;
const EventPopupTag = styled.div`
  display: inline-block;
  padding: 6.5px 12px;
  margin-bottom: 20px;
  background-color: #333;
  color: #fff;
  border-radius: 100px;
  font-size: 1.4rem;
  font-family: OneMobileTitle;
`;

const PopupContents = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  
  @media ${theme.mq.mobile}{
    gap: 0;
    width: 100%;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
  }

  @media ${theme.mq.mobile} {
    width: 22.2222vw;
    margin-bottom: 6.6667vw;
  }
`;


const DayShowCheckArea = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 1.4rem;
  font-family: OneMobileTitle;
  input[type="checkbox"] {
    outline: 2px solid #fff;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    height: 14px;
    width: 14px;
    background-color: transparent;
    margin-right: 8px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
    border-radius: 2px;
  }

  input[type="checkbox"]::after {
    background-image: url(${popupCheck});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    content: '';
    display: block;
    width: 100%;
    height: 100%;
  }

  input[type="checkbox"]:checked {
    background: #fff;
  }

  input[type="checkbox"]:checked::after {
    display: block;
  }
  
  @media ${theme.mq.mobile}{
    font-size: 3.8889vw;
    input[type="checkbox"] {
      margin-right: 2.2222vw;
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  cursor: pointer;
 .eventBtn {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 240px;
   height: 60px;
   background: #fff;
   font-size: 2.0rem;
   color: #5843CC;
   border-radius: 100px;
   font-family: OneMobileTitle;
 }
  @media ${theme.mq.mobile}{
    gap: 3.3333vw;
    .eventBtn {
      width: 66.6667vw;
      height: 13.3333vw;
      font-size: 4.4444vw;
    }
  }
`;

const CloseBtn = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
  width: 24px;
  height: 24px;
  img {
    width: 100%;
  }
  @media ${theme.mq.mobile}{
    top: 5.5556vw;
    right: 5.5556vw;
    width: 6.6667vw;
    height: 6.6667vw;
  }
`;
export default ClingSwapEventPopup;

