import React from 'react';
import styled from "styled-components";
import {cvtxGif, eventBg, eventBgM} from "../../../assets/img";
import {theme} from "../../../styles/theme";
const Intro = () => {
    return (
        <EventIntroWrap>
            <GifWrap><img src={cvtxGif} alt=""/></GifWrap>
            <TextWrap>
                <h2>Try Cling for Prize Pool</h2>
                <p>310,000 $CVTX</p>
                <Description>
                    <li><span>Event Duration</span>5 pm (KST), September 26th, 2023 ~ <br className={'onlyMobile'}/>4:59 pm (KST), October 26th, 2023</li>
                    <li><span>Result Announcement</span>November 5th, 2023</li>
                    <li><span>Prize Distribution</span>November 11th, 2023</li>
                </Description>
            </TextWrap>
        </EventIntroWrap>
    );
};

export default Intro;

const EventIntroWrap = styled.div`
  background: url(${eventBg}) #5843CC no-repeat top center;
  background-size: cover;
  height: 100vh;
  color: #fff;
  font-family: OneMobile;
  font-weight: 700;
  @media ${theme.mq.mobile}{
    background: url(${eventBgM}) #5843CC no-repeat top center;
    background-size: cover;
    height: calc(100vh - 30px);
  }
`;
const GifWrap = styled.div`
  width: 512px;
  padding-top: 6vh;
  margin: 0 auto;
  img {
    width: 100%;
  }
 
  @media ${theme.mq.mobile}{
    padding-top: 0;
    max-width: 360px;
    width: 68.3333vw;
  }
`;
const TextWrap = styled.div`
  position: absolute;
  top: 55vh;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  font-family: OneMobileTitle;
  font-weight: 400;
  
  h2 {
    font-size: 5.4rem;
    margin-bottom: 24px;
  }
  p {
    font-size: 8rem;
    margin-bottom: 73px;
  }
  @media ${theme.mq.mobile}{
    top: 36vh;
    white-space: nowrap;
    h2 {
      margin-bottom: 3.3333vw;
      font-size: 6.6667vw;
    }
    p {
      font-size: 10vw;
      margin-bottom: 15.5556vw;
    }
  }
`;
const Description = styled.div`
    display: flex;
  flex-wrap: wrap;
  font-size: 2.0rem;
  width: 1200px;
  padding: 0 148px;
  row-gap: 18px;
  justify-content: space-between;
  li {
    list-style: none;
    font-family: OneMobile;
    font-weight: 400;
    span {
      padding: 0 16px;
      font-family: OneMobileTitle;
    }
  }
  @media ${theme.mq.mobile}{
    flex-direction: column;
    align-items: center;
    row-gap: 8.8889vw;
    width: unset;
    font-size: 3.8889vw;
    padding: 0;
    li {
      
      white-space: nowrap;
      span {
        display: block;
        margin-bottom: 3.8889vw;
        
      }
      &:first-child {
        text-align: left;
        line-height: 1.5;
        span {
          text-align: center;
          line-height: normal;
        }
      }
    }
  }
`;