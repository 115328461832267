import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  glass3,
  heal,
  nft,
  nftImg,
  weakAttack,
  spaceMarine,
  circle2,
  marketTitle,
  NFTMarket,
  glass4,
  wave5,
} from '../../../assets/img';
import { TextBox } from '../wallet';

const NFT = () => {
  const { t } = useTranslation();

  return (
    <>
      <SectionStyle id="3">
        <Division src={glass3} alt="division" />
        <Division2 src={circle2} alt="division" />
        <h1>
          <img src={nft} alt="title" />
        </h1>
        <NFTImg src={nftImg} alt="img" />
        <TextBox>
          <h1>{t('distinction')}</h1>
          <p dangerouslySetInnerHTML={{ __html: t('distinction_des') }} />
          <h1>
            C2C<span>(Customer To Customer)</span>
          </h1>
          <p dangerouslySetInnerHTML={{ __html: t('c2c_des') }} />
          <h1>{t('value')}</h1>
          <p dangerouslySetInnerHTML={{ __html: t('value_des') }} />
        </TextBox>
        <section>
          <img src={heal} alt="card" />
          <img src={weakAttack} alt="card" />
          <img src={spaceMarine} alt="card" />
        </section>
      </SectionStyle>
      <SectionStyle2>
        <div>
          <img src={marketTitle} alt="market" />
        </div>
        <MarketImg src={NFTMarket} alt="market" />
        <TextBox>
          <h1>{t('ownership')}</h1>
          <p dangerouslySetInnerHTML={{ __html: t('ownership_des') }} />
          <h1>{t('trade')}</h1>
          <p>{t('trade_des')}</p>
          <h1>{t('salesMethod')}</h1>
          <p dangerouslySetInnerHTML={{ __html: t('salesMethod_des') }} />
        </TextBox>
        <Division3 src={glass4} alt="division" />
        <Division4 src={wave5} alt="wave" />
      </SectionStyle2>
    </>
  );
};
export default NFT;

const SectionStyle = styled.section`
  position: relative;
  text-align: center;
  overflow: hidden;
  padding-top: 80px;
  h1 {
    font-family: SpoqaBold;
    img {
      height: 87px;
    }
  }
  section {
    font-family: SpoqaMedium;
    padding-bottom: 80px;
    img {
      height: 300px;
    }
  }

  @media ${({ theme }) => theme.mq.tablet} {
    h1 {
      img {
        height: 70px;
      }
    }
    section {
      img {
        height: 192px;
      }
    }
  }

  @media ${({ theme }) => theme.mq.mobile} {
    padding-top: 25px;
    h1 {
      img {
        height: 48px;
      }
    }
    section {
      padding-bottom: 43px;
      img {
        height: 129px;
      }
    }
  }
`;

const NFTImg = styled.img`
  height: 430px;
  margin: 80px 0;

  @media ${({ theme }) => theme.mq.tablet} {
    height: 238px;
  }

  @media ${({ theme }) => theme.mq.mobile} {
    height: 148px;
    margin: 50px 0 0 0;
  }
`;

const Division = styled.img`
  position: absolute;
  left: 0;
  top: -250px;
  width: 400px;

  @media ${({ theme }) => theme.mq.tablet} {
    width: 300px;
    top: -224px;
  }
  @media ${({ theme }) => theme.mq.mobile} {
    width: 128px;
    top: -76px;
  }
`;
const Division2 = styled.img`
  position: absolute;
  right: 0;
  top: 200px;
  height: 348px;

  @media ${({ theme }) => theme.mq.tablet} {
    height: 258px;
    top: 130px;
  }
  @media ${({ theme }) => theme.mq.mobile} {
    top: 10px;
    height: 158px;
  }
`;

const Division3 = styled.img`
  position: absolute;
  right: 0;
  bottom: -60px;
  @media ${({ theme }) => theme.mq.tablet} {
    height: 400px;
  }
  @media ${({ theme }) => theme.mq.mobile} {
    height: 155px;
  }
`;

const SectionStyle2 = styled(SectionStyle)`
  background-color: ${({ theme }) => theme.colors.secondaryBg};
  div:first-of-type {
    margin-bottom: 80px;
  }

  @media ${({ theme }) => theme.mq.tablet} {
    div:first-of-type {
      img {
        height: 150px;
      }
    }
  }

  @media ${({ theme }) => theme.mq.mobile} {
    div:first-of-type {
      margin-bottom: 50px;
      img {
        height: 100px;
      }
    }
  }
`;

const MarketImg = styled.img`
  height: 372px;
  @media ${({ theme }) => theme.mq.mobile} {
    height: 217px;
  }
`;

const Division4 = styled.img`
  overflow: hidden;
  position: absolute;
  bottom: -1364px;
  left: calc(50% - 960px);

  @media ${({ theme }) => theme.mq.tablet} {
    transform: scale(0.55);
    bottom: -1352px;
  }
  @media ${({ theme }) => theme.mq.mobile} {
    transform: scale(0.2);
    bottom: -1215px;
  }
`;
