import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { carrieLogoM } from '../../assets/img';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Dropdown from './Dropdown';

const Footer = () => {
  const location = useLocation();
  const { t } = useTranslation();

  const isMobile = useMediaQuery({
    query: '(max-width: 600px)',
  });
  const isTablet = useMediaQuery({
    query: '(max-width: 1024px)',
  });
  const needToHide =
    location.pathname === '/clingAdmin' ||
    location.pathname === '/clingAdmin/list' ||
    location.pathname === '/clingAdmin/edit';

  if (needToHide) return null;
  return (
    <FooterStyle>
      <ContentBox>
        <Logo src={carrieLogoM} alt="logo" />
        {isMobile ? (
          <>
            <MobileTextWrapper>
              <p>
                <b>{t('carrieverse')}</b>
              </p>
              <span>
                <b>{t('CEO')}&nbsp;</b> {t('name')}
              </span>

              <InfoText>{t('address')}</InfoText>
            </MobileTextWrapper>
          </>
        ) : (
          <div>
            <b>{t('carrieverse')}</b>
            <br />
            <>
              <b>{t('CEO')}</b> &nbsp;
              {t('name')}
              &nbsp; | &nbsp;
            </>
            {t('address')}
            <br />
          </div>
        )}
        <ContactBtn>
          <a href="mailto:biz@imantisco.com">Contact Us</a>
        </ContactBtn>
        <Dropdown />
        <CopyText> CARRIEVERSE CORP. All RIGHTS RESERVED.</CopyText>
      </ContentBox>
    </FooterStyle>
  );
};
export default Footer;

const FooterStyle = styled.footer`
  background: ${({ theme }) => theme.colors.main};
  z-index: 10;
  position: relative;
  height: 406px;

  @media ${({ theme }) => theme.mq.tablet} {
    height: 427px;
  }
  @media ${({ theme }) => theme.mq.mobile} {
    height: 532px;
  }
`;

const ContentBox = styled.div`
  ${({ theme }) => theme.positions.flexColumnY};

  div {
    color: ${({ theme }) => theme.colors.mainText};
    line-height: 24px;
    text-align: center;
    font-size: 1.4rem;
    font-family: SpoqaMedium;
    b {
      font-family: SpoqaBold;
    }
    @media ${({ theme }) => theme.mq.mobile} {
    }
  }

  span {
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    @media ${({ theme }) => theme.mq.mobile} {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }
  }
  @media ${({ theme }) => theme.mq.mobile} {
    div {
      line-height: 20px;
      font-size: 12px;
    }
  }
`;
const MobileTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const InfoText = styled.div`
  font-family: SpoqaMedium;
  font-weight: 500;
  width: 200px;
`;

const Logo = styled.img`
  margin: 48px 0 24px 0;
  width: 150px;
  @media ${({ theme }) => theme.mq.tablet} {
    width: 100px;
  }
  @media ${({ theme }) => theme.mq.mobile} {
    margin: 32px 0 20px;
    width: 90px;
  }
`;

const ContactBtn = styled.span`
  font-family: Poppin400, sans-serif;
  background: ${({ theme }) => theme.colors.point};
  border-radius: 50px;
  font-size: 14px;
  margin: 16px 0 36px 0;
  display: flex;
  width: 136px;
  height: 36px;
  padding: 8px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  font-weight: 400;
  line-height: 14px;
  @media ${({ theme }) => theme.mq.tablet} {
    margin: 16px 0 32px 0;
  }

  @media ${({ theme }) => theme.mq.mobile} {
    padding: 8px 16px;
    margin: 16px 0 24px 0;
  }
`;
const CopyText = styled.span`
  font-family: SpoqaMedium;
  font-weight: 400;
`;
