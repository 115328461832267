import i18next from "i18next";

export const clickHandler = (lang) => {
  i18next.changeLanguage(lang).then((r) => r);
};

export const getBrowserLang = () => {
  const browserLang = navigator.language;
  let settingLang = browserLang;

  if (browserLang === "en-US") {
    settingLang = "en";
  }
  if (browserLang === "ko-KR") {
    settingLang = "ko";
  }
  return settingLang;
};
