import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { formatDate } from '../../../utills/formatData';
import { fetchBoardData } from '../../../api';
import { useTranslation } from 'react-i18next';
import Pagination from './Pagination';
import { useMediaQuery } from 'react-responsive';
import {
  polygonLogo,
  twitterP,
  redditP,
  telegramP,
  discordP,
  media,
  partnerPress,
  pressCircle,
  pressCircle2,
  pressCircle3,
  ring2,
} from '../../../assets/img';

const PressRelease = () => {
  const [mediaContents, setMediaContents] = useState([]);
  const [limit, setLimit] = useState(1);
  const [page, setPage] = useState(1);
  const offset = (page - 1) * limit;
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const isTablet = useMediaQuery({
    query: '(min-width: 601px) and (max-width: 1024px)',
  });

  useEffect(() => {
    const getBoardData = async () => {
      const boardData = await fetchBoardData();
      setMediaContents(boardData);
    };
    getBoardData();
  }, []);

  useEffect(() => {
    if (isMobile) {
      setLimit(1);
    } else if (isTablet) {
      setLimit(2);
    } else {
      setLimit(3);
    }
  }, [isMobile, isTablet]);

  return (
    <PressSection id="5">
      <Division1 src={pressCircle} alt="division" />
      <Division2 src={pressCircle2} alt="division" />
      <Division3 src={pressCircle3} alt="division" />
      <h1>
        <img src={media} alt="title" />
      </h1>
      <Container>
        {mediaContents.slice(offset, offset + limit).map((card, i) => (
          <Card key={i} onClick={() => navigate(`detail/${card.id}`)}>
            <div>
              <img src={card.thumbnailUrl} alt="img" />
            </div>
            <div>
              {i18n.language === 'ko' ? (
                <>
                  <h2>{card.title_ko}</h2>
                  <span>{card.contentPreview_ko}</span>
                  <p>{formatDate(card.date.seconds)}</p>
                </>
              ) : (
                <>
                  <h2>{card.title_en}</h2>
                  <span>{card.contentPreview_en}</span>
                  <p>{formatDate(card.date.seconds)}</p>
                </>
              )}
            </div>
          </Card>
        ))}
      </Container>
      <Division4 src={ring2} alt="division" />
      <Pagination
        total={mediaContents.length}
        limit={limit}
        page={page}
        setPage={setPage}
      />
    </PressSection>
  );
};
export default PressRelease;

const PressSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 80px;
  position: relative;
  h1 {
    :first-of-type {
      margin: 80px 0;
    }
    :nth-of-type(2) {
      margin: 55px 0 78px 0;
    }
  }
  @media ${({ theme }) => theme.mq.tablet} {
    h1 {
      img {
        width: 263px;
      }
    }
  }
  @media ${({ theme }) => theme.mq.mobile} {
    h1 {
      img {
        width: 130px;
      }
    }
  }
`;
const Division1 = styled.img`
  position: absolute;
  top: -20px;
  left: 18%;
  @media ${({ theme }) => theme.mq.tablet} {
    width: 69px;
  }
`;
const Division2 = styled.img`
  position: absolute;
  top: 90px;
  right: 20%;
  @media ${({ theme }) => theme.mq.tablet} {
    width: 50px;
    top: 50px;
  }
  @media ${({ theme }) => theme.mq.mobile} {
    top: 260px;
    right: 8%;
  }
`;
const Division3 = styled.img`
  position: absolute;
  top: 450px;
  right: 2%;
  @media ${({ theme }) => theme.mq.tablet} {
    width: 145px;
    top: 500px;
  }
  @media ${({ theme }) => theme.mq.mobile} {
    width: 106px;
    top: 690px;
  }
`;
const Division4 = styled.img`
  position: absolute;
  bottom: -460px;
  left: 0;
  @media ${({ theme }) => theme.mq.tablet} {
    width: 400px;
    bottom: -283px;
  }
  @media ${({ theme }) => theme.mq.mobile} {
    width: 220px;
    bottom: -160px;
    left: -35px;
  }
`;
const Container = styled.div`
  /* display: grid;
  grid-template-columns: repeat(auto-fit, minmax(267px, 1fr)); */
  display: flex;
  gap: 20px;
  @media ${({ theme }) => theme.mq.tablet} {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
`;
const Card = styled.article`
  width: 267px;
  cursor: pointer;
  img {
    width: 100%;
    height: 240px;
  }
  h2 {
    font-size: 22px;
    margin: 20px 0;
    word-break: keep-all;
    color: rgba(91, 72, 217, 1);
    min-height: 100px;
    font-weight: 700;
  }
  span {
    font-size: 16px;
  }
  p {
    color: ${({ theme }) => theme.colors.grey};
    word-break: keep-all;
    white-space: pre-line;
    :last-child {
      font-size: ${({ theme }) => theme.fontSizes.small};
      margin-top: 25px;
    }
  }
  @media ${({ theme }) => theme.mq.tablet} {
    width: 210px;
    img {
      height: 157px;
    }
    h2 {
      font-size: 16px;
      margin: 20px 0 0 0;
      line-height: 22px;
    }
    span {
      font-size: 12px;
    }
  }
`;
