import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { LIST } from './list';
import { plus_icon } from '../../assets/img';
import { theme } from '../../styles/theme';

const Dropdown = () => {
  const [openDropdown, setOpenDropdown] = useState(null);
  const containerRef = useRef(null);

  const toggleDropdown = (index) => {
    setOpenDropdown(openDropdown === index ? null : index);
  };

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setOpenDropdown(null);
    }
  };

  useEffect(() => {
    if (openDropdown !== null) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [openDropdown]);

  return (
    <Container ref={containerRef}>
      {LIST.map((item, index) => (
        <Category key={index}>
          {openDropdown === index && (
            <DropdownMenu>
              {item.items.map((item, Index) => (
                <DropdownItem key={Index}>
                  <a href={item.link} target="_blank" rel="noopener noreferrer">
                    {item.title}
                  </a>
                </DropdownItem>
              ))}
            </DropdownMenu>
          )}

          <CategoryLabel
            onClick={() => toggleDropdown(index)}
            isOpen={openDropdown === index}
          >
            {item.label}
            <RotatableIcon
              src={plus_icon}
              alt=""
              rotated={openDropdown === index}
            />
          </CategoryLabel>
        </Category>
      ))}
    </Container>
  );
};

const Container = styled.div`
  font-family: SpoqaMedium;
  display: flex;
  gap: 24px;
  margin: 0px 0 24px 0px;
  @media ${theme.mq.tablet} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }
  @media ${({ theme }) => theme.mq.mobile} {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
`;

const Category = styled.div`
  position: relative;
`;

const CategoryLabel = styled.div`
  font-family: SpoqaMedium;
  font-size: 400;
  display: flex;
  width: 227px;
  padding: 6px 20px;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  height: 36px;
  border-radius: 50px;
  font-size: 14px;
  border: 2px solid #7766e8;

  background: ${({ isOpen }) => (isOpen ? '#7766e8' : '#5b48d9')};

  :hover {
    display: flex;
    align-items: center;
    background-color: #7766e8;

    @media ${({ theme }) => theme.mq.mobile} {
      background-color: ${({ isOpen }) => (isOpen ? '#7766e8' : '#5b48d9')};
    }
  }

  @media ${({ theme }) => theme.mq.mobile} {
    width: 201px;
    height: 40px;
    padding: 8px 20px;
  }
`;
const RotatableIcon = styled.img`
  width: 12px;
  height: 12px;
  margin: 0%;
  cursor: pointer;
  transition: transform 0.3s ease;
  ${(props) =>
    props.rotated &&
    `
    transform: rotate(45deg);
  `}
  @media ${({ theme }) => theme.mq.mobile} {
    width: 10px;
    height: 10px;
  }
`;

const DropdownMenu = styled.div`
  position: absolute;
  font-family: SpoqaMedium;
  bottom: 100%;
  margin-bottom: 4px;
  display: flex;
  width: 100%;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  border-radius: 18px;
  background: #7766e8;
  border: 2px solid #7766e8;
  @media ${({ theme }) => theme.mq.mobile} {
    width: 201px;
    font-size: 12px;
  }
`;

const DropdownItem = styled.div`
  font-family: SpoqaMedium;
  display: flex;
  width: 100%;
  gap: 4px;

  p {
    padding: 4px 10px;
    font-weight: 400;
    color: #4d3db7;
  }

  a {
    white-space: nowrap;
    font-family: SpoqaMedium;
    text-align: start;
    width: 100%;
    padding: 4px 10px;
    gap: 10px;
    cursor: pointer;
    color: #ffffff;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    &:hover {
      font-family: SpoqaBold;
      border-radius: 10px;
      font-size: 15px;
      background: #5d4ad9;
      font-weight: 700;
    }

    @media ${({ theme }) => theme.mq.mobile} {
      width: 100%;
      font-size: 12px;
    }
  }

  @media ${({ theme }) => theme.mq.mobile} {
    width: 100%;
    font-size: 12px;
  }
`;

export default Dropdown;
