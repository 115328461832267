import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { useMediaQuery } from "react-responsive";
import { useState, useEffect } from "react";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import { postStore } from "../../store/admin";
import { formatDate } from "../../utills/formatData";

const Detail = () => {
  const [data, setData] = useState({});
  const { id } = useParams();
  const navigate = useNavigate();
  const { getDetailData } = postStore();
  const { i18n } = useTranslation();

  const checkStoreDetailData = async (id) => {
    const res = await getDetailData(id);
    if (res.exists()) {
      setData(res.data());
    } else {
      navigate("/");
    }
  };

  useEffect(() => {
    if (id !== undefined) checkStoreDetailData(id).then((r) => r);
  }, [id]);

  return (
    <DetailContent>
      {i18n.language === "ko" ? (
        <>
          <h1>{data.title_ko}</h1>
          <span>{formatDate(data.date?.seconds)}</span>
          <div>
            <img src={data.imgUrl} alt="article" />
          </div>
          <p>{data.content_ko}</p>
          <h2>{t("source")}</h2>
          <DivStyle
            onClick={() => {
              window.open(data.hyperlink);
            }}
          >
            {data.hyperlink}
          </DivStyle>
        </>
      ) : (
        <>
          <h1>{data.title_en}</h1>
          <span>{formatDate(data.date?.seconds)}</span>
          <div>
            <img src={data.imgUrl} alt="article" />
          </div>
          <p>{data.content_en}</p>
          <h2>{t("source")}</h2>
          <DivStyle
            onClick={() => {
              window.open(data.hyperlink);
            }}
          >
            {data.hyperlink}
          </DivStyle>
        </>
      )}
      <ButtonWrapper>
        <button onClick={() => navigate(-1)}>{t("list")}</button>
      </ButtonWrapper>
    </DetailContent>
  );
};

const DetailContent = styled.main`
  width: 792px;
  margin: 160px auto 60px auto;
  h1 {
    margin-bottom: 24px;
    font-size: 28px;
    color: ${({ theme }) => theme.colors.main};
  }
  span {
    font-size: 16px;
  }
  div {
    img {
      margin: 20px 0 60px 0;
      width: 100%;
    }
  }
  h2 {
    width: 56px;
    height: 20px;
    line-height: 20px;
    background-color: ${({ theme }) => theme.colors.main};
    border-radius: 8px;
    color: white;
    text-align: center;
    font-size: 16px;
    margin-bottom: 5px;
  }
  p {
    line-height: 28px;
    padding-bottom: 30px;
    white-space: pre-wrap;
    font-size: 18px;
  }

  @media ${({ theme }) => theme.mq.tablet} {
    width: 64%;
    h1 {
      font-size: 24px;
    }
    p {
      font-size: 16px;
    }
  }

  @media ${({ theme }) => theme.mq.mobile} {
    width: 90%;
    margin-top: 140px;
    margin-bottom: 50px;
    span {
      font-size: 12px;
    }
    h1 {
      margin-bottom: 10px;
      font-size: 18px;
    }
    div {
      img {
        margin: 40px 0;
      }
    }
  }
  h2 {
    font-size: 12px;
  }
`;
export default Detail;

const DivStyle = styled.div`
  padding-bottom: 30px;
  border-bottom: 1px solid #e8e8e8;
  color: rgb(102, 102, 102);
  font-size: 18px;
  overflow: hidden;
  cursor: pointer;

  @media ${({ theme }) => theme.mq.tablet} {
    font-size: 14px;
  }
`;

const ButtonWrapper = styled.div`
  text-align: center;
  margin: 55px 0;

  button {
    color: black;
    width: 140px;
    height: 40px;
    border: 2px solid ${({ theme }) => theme.colors.main};
    border-radius: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  }
  @media ${({ theme }) => theme.mq.tablet} {
    margin: 50px 0 100px 0;
  }

  @media ${({ theme }) => theme.mq.mobile} {
    margin: 50px 0 40px 0;

    button {
      font-size: 12px;
      width: 103px;
      height: 28px;
    }
  }
`;
