import styled from "styled-components";
import { Link } from "react-scroll";
import useNav from "../../hooks/useNav";

const List = ({ item, idx, selectedMenu, setSelectedMenu }) => {
  const { goToClickedNumber } = useNav();

  return (
    <Link
      onClick={() => goToClickedNumber(item.key)}
      activeClass="active"
      key={item.key}
      to={item.key}
      spy={true}
      smooth={true}
      offset={-100}
    >
      <ListStyle
        value={idx + 1}
        selectedMenu={selectedMenu}
        onClick={() => {
          setSelectedMenu(item.key);
        }}
      >
        {item.name}
      </ListStyle>
    </Link>
  );
};
export default List;

const ListStyle = styled.li`
  font-family: ${({ selectedMenu, value }) =>
    selectedMenu === value ? "Poppin700" : "Poppin500"};
  margin-right: 55px;

  cursor: pointer;
  :hover {
    font-family: Poppin700, sans-serif;
  }
`;
