import styled from "styled-components";
import { mainM, mainT, mainL, toBottom, clingLogo } from "../../../assets/img";
import { theme } from "../../../styles/theme";
import {Link} from "react-router-dom";

const FirstSection = () => {
  return (
    <SectionStyle id="1">
      <Logo src={clingLogo} alt="logo" />
      <div
        onClick={() =>
          window.scrollTo({ top: window.innerHeight - 100, behavior: "smooth" })
        }
      >
        <img src={toBottom} alt="btn" />
      </div>
      {/*<button><Link to={'/event'} target={'_blank'}>Event Detail</Link></button>*/}
    </SectionStyle>
  );
};
export default FirstSection;

const SectionStyle = styled.section`
  position: relative;
  height: 100vh;
  background: white url(${mainL}) no-repeat;
  //background-size: cover;
  background-size: 100% 100%;

  div {
    position: absolute;
    bottom: 0;
    left: calc(50% - 25px);
  }
  button {
    position: absolute;
    bottom: 200px;
    width: 340px;
    height: 88px;
    left: calc(50% - 170px);
    background: linear-gradient(114deg, rgba(255, 255, 255, 0.70) 0.74%, rgba(255, 255, 255, 0.56) 93.99%);
    box-shadow: 0px 0px 5.33333px 0px rgba(67, 96, 238, 0.04), 0px 2px 4px 0px rgba(0, 0, 0, 0.10);
    backdrop-filter: blur(8px);
    border-radius: 100px;
    overflow: hidden;
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      color: #5843CC; 
      font-size: 2.4rem;
      font-family: OneMobileTitle;
    }
  }
  @media ${theme.mq.tablet} {
    background: white url(${mainT}) no-repeat;
    background-size: 100% 100%;
    div {
      display: none;
    }
  }
  @media ${theme.mq.mobile} {
    margin-top: 80px;
    background: white url(${mainM}) no-repeat;
    height: 478px;
    //height: 140rem;
    background-size: 100% 100%;

    button {
      bottom: 50px;
      left: calc(50% - 100px);
      width: 200px;
      height: 60px;
    }
  }
`;

const Logo = styled.img`
  position: absolute;
  top: calc(50vh - 60px);
  left: calc(50% - 223px);
  @media ${theme.mq.tablet} {
    height: 110px;
    top: calc(50% - 55px);
    left: calc(50% - 172px);
  }
  @media ${theme.mq.mobile} {
    height: 42px;
    top: calc(50% - 21px);
    left: calc(50% - 65px);
  }
`;
