import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import { SectionTemplate } from './WhatIsCling';
import {
  carrieverse,
  carrieBg,
  carrieLogo,
  carrieSuperkola,
  appStore,
  googlePlay,
  wave2,
  superkola,
  kolaLogo,
  kola3D,
  kolaPark,
  CVTX,
  circle,
  ring,
  wave3,
  cvtxImg,
} from '../../../assets/img';
import {
  CARRIE_DESCRIPTION,
  KOLA_DESCRIPTION,
  CARD_DATA,
} from '../../../utills/staticData';

const AboutCVTX = () => {
  const { i18n } = useTranslation();

  return (
    <ExtendedSectionTemplate>
      <h1>
        <img src={carrieverse} alt="title" />
      </h1>
      <Division src={wave2} alt="division" />
      {i18n.language === 'ko' && <h2>캐리버스</h2>}
      <CarrieTemplate>
        <Logo src={carrieLogo} />
        <ul>
          {CARRIE_DESCRIPTION.map((el, index) =>
            i18n.language === 'ko' ? (
              <li key={index}>
                <span>{el.name}</span>
                {el.answer}
              </li>
            ) : (
              <li key={index}>
                <span>{el.name_en}</span>
                {el.answer_en}
              </li>
            )
          )}
        </ul>
        <ImgBox>
          <Figure src={carrieSuperkola} alt="img" />
          <div>
            <img data-for="alert" data-tip src={appStore} alt="app" />
            <a
              href="https://play.google.com/store/apps/details?id=com.carrieverse.carriepark"
              rel="noreferrer"
              target={'_blank'}
            >
              <img data-for="alert" src={googlePlay} alt="google" />
            </a>
          </div>
        </ImgBox>
      </CarrieTemplate>
      <ReactTooltip
        backgroundColor="#333"
        place="top"
        effect="solid"
        id="alert"
      >
        <span>coming soon</span>
      </ReactTooltip>
      <VideoWrapper>
        <video width="100%" controls>
          <source src="https://carrieverse-platform.s3.ap-northeast-2.amazonaws.com/carrieverseMovie.mp4" />
        </video>
      </VideoWrapper>
      <KolaTitle>
        <img src={superkola} alt="title" />
      </KolaTitle>
      {i18n.language === 'ko' && <h2>슈퍼콜라</h2>}
      <Division3 src={ring} alt="ring" />
      <KolaTemplate>
        <Division2 src={circle} alt="circle" />
        <Logo2 src={kolaLogo} alt="logo" />
        <ul>
          {KOLA_DESCRIPTION.map((el, index) =>
            i18n.language === 'ko' ? (
              <li key={index}>
                <span>{el.name}</span>
                {el.answer}
              </li>
            ) : (
              <li key={index}>
                <span>{el.name_en}</span>
                {el.answer_en}
              </li>
            )
          )}
        </ul>
        <ImgBox>
          <Figure2 src={kola3D} alt="img" />
          <div>
            <img data-for="alert" data-tip src={appStore} alt="app" />
            <img data-for="alert" data-tip src={googlePlay} alt="google" />
          </div>
        </ImgBox>
      </KolaTemplate>
      <VideoWrapper>
        <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/TzpOVDDrowA"
          allowFullScreen
          title="youtube"
        />
      </VideoWrapper>
      <h1>
        <img src={CVTX} alt="title" />
      </h1>
      <CVTXTemplate>
        <img src={cvtxImg} alt="diagram" />
      </CVTXTemplate>
      <CardBox>
        {CARD_DATA.map((card, index) => (
          <CVTXCard key={index}>
            <img src={card.src} alt="symbol" />
            <div>
              {i18n.language === 'ko' ? (
                <p>{card.title}</p>
              ) : (
                <p>{card.title_en ?? card.title}</p>
              )}
              <ul>
                {i18n.language === 'ko'
                  ? card.list.map((li, key) =>
                      li.split('<br/>').map((line) => {
                        return (
                          <li>
                            {line}
                            <br />
                          </li>
                        );
                      })
                    )
                  : card.list_en.map((li, key) => <li key={li + '#'}>{li}</li>)}
              </ul>
            </div>
          </CVTXCard>
        ))}
      </CardBox>
      <Division4 src={wave3} alt="division" />
    </ExtendedSectionTemplate>
  );
};
export default AboutCVTX;

const ExtendedSectionTemplate = styled(SectionTemplate)`
  background: ${({ theme }) => theme.colors.mainBg};
  padding-bottom: 64px;
  h2 {
    font-size: 3.6rem;
    margin-top: 40px;
    font-weight: 700;
  }
  @media ${({ theme }) => theme.mq.tablet} {
    h1 {
      img {
        height: 55px;
      }
    }
  }

  @media ${({ theme }) => theme.mq.mobile} {
    h1 {
      img {
        height: 40px;
      }
    }
    h2 {
      font-size: 24px;
      margin-top: 16px;
    }
  }
`;

const Division = styled.img`
  position: absolute;
  left: calc(50% - 2242px);
  top: -1130px;
  @media ${({ theme }) => theme.mq.tablet} {
    top: -1999px;
    transform: scale(0.4);
  }
  @media ${({ theme }) => theme.mq.mobile} {
    top: -1954px;
    transform: scale(0.23);
  }
`;
const Division2 = styled.img`
  position: absolute;
  top: 1520px;
  right: -165px;
  @media ${({ theme }) => theme.mq.tablet} {
    top: -50px;
  }
  @media ${({ theme }) => theme.mq.mobile} {
    width: 62px;
  }
`;
const Division4 = styled.img`
  position: absolute;
  left: calc(50% - 960px);
  bottom: -1808px;
  @media ${({ theme }) => theme.mq.tablet} {
    transform: scale(0.55);
    bottom: -1761px;
  }
  @media ${({ theme }) => theme.mq.mobile} {
    transform: scale(0.2);
    bottom: -1716px;
  }
`;

const CarrieTemplate = styled.div`
  position: relative;
  background-image: url(${carrieBg});
  background-color: white;
  width: 840px;
  height: 690px;
  border-radius: 40px 40px 0 0;
  margin: 80px auto;
  text-align: left;
  display: flex;
  flex-direction: column;

  div {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: -160px;
    bottom: 44px;
    img {
      width: 200px;
      :first-of-type {
        margin-bottom: 20px;
      }
    }
  }

  ul {
    font-family: SpoqaBold, Poppin700, sans-serif;
    margin: 16px 0 0 17px;
    li {
      font-family: SpoqaBold, Poppin700, sans-serif;
      font-weight: bold;
      font-size: 1.8rem;
      line-height: 30px;
      text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
      span {
        color: ${({ theme }) => theme.colors.main};
        margin-right: 10px;
      }
    }
  }

  @media ${({ theme }) => theme.mq.tablet} {
    width: 460px;
    background-position: right;
    margin: 77px auto 30px auto;

    div {
      position: relative;
      flex-direction: row;
      right: 0;
      bottom: 8px;
      img {
        width: 144px;
        height: 42px;
        :first-of-type {
          margin-right: 20px;
        }
      }
    }
  }

  @media ${({ theme }) => theme.mq.mobile} {
    width: 320px;
    height: 480px;
    margin: 38px auto;
    background-size: cover;
    div {
      justify-content: center;
      bottom: 3px;
      height: 29px;
      img {
        width: 100px;
        height: 29px;
        :first-of-type {
          margin-right: 14px;
        }
      }
    }
    ul {
      margin: 6px 0 0 13px;
      li {
        font-size: 12px;
        line-height: 22px;
      }
    }
  }
`;

const VideoWrapper = styled.div`
  position: inherit;
  width: 844px;
  height: 461px;
  margin: auto;

  @media ${({ theme }) => theme.mq.tablet} {
    width: 580px;
    height: 326px;
  }
  @media ${({ theme }) => theme.mq.mobile} {
    width: 320px;
    height: 180px;
  }
`;
const KolaTemplate = styled(CarrieTemplate)`
  background-image: url(${kolaPark});
`;
const CVTXTemplate = styled(CarrieTemplate)`
  height: 1050px;
  background: none;
  img {
    width: 100%;
    margin: auto;
  }

  @media ${({ theme }) => theme.mq.tablet} {
    height: 580px;
    img {
      width: 368px;
    }
  }

  @media ${({ theme }) => theme.mq.mobile} {
    height: 404px;
    img {
      width: 256px;
    }
  }
`;
const ImgBox = styled.span`
  position: absolute;
  bottom: 0;
  left: calc(50% - 206px);

  @media ${({ theme }) => theme.mq.tablet} {
    left: calc(50% - 154px);
  }
  @media ${({ theme }) => theme.mq.mobile} {
    left: calc(50% - 114px);
    bottom: 14px;
  }
`;
const Logo = styled.img`
  width: 280px;
  margin-top: 10px;
  @media ${({ theme }) => theme.mq.mobile} {
    width: 194px;
  }
`;
const Logo2 = styled.img`
  width: 207px;
  margin: 31px 0 15px 17px;

  @media ${({ theme }) => theme.mq.mobile} {
    width: 144px;
  }
`;

const KolaTitle = styled.h3`
  padding-top: 100px;

  @media ${({ theme }) => theme.mq.tablet} {
    padding-top: 90px;
    img {
      height: 68px;
    }
  }
  @media ${({ theme }) => theme.mq.mobile} {
    padding-top: 60px;
    img {
      height: 50px;
    }
  }
`;
const Figure = styled.img`
  width: 413px;
  align-self: center;

  @media ${({ theme }) => theme.mq.tablet} {
    width: 308px;
  }
  @media ${({ theme }) => theme.mq.mobile} {
    width: 214px;
  }
`;
const Figure2 = styled.img`
  position: relative;
  width: 422px;
  z-index: 1;
  top: 12px;
  left: calc(50% - 211px);

  @media ${({ theme }) => theme.mq.tablet} {
    width: 282px;
    left: calc(50% - 141px);
  }

  @media ${({ theme }) => theme.mq.mobile} {
    width: 196px;
    align-self: center;
    left: calc(50% - 98px);
  }
`;

const CardBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 846px;
  gap: 88px 42px;
  margin: 130px auto 126px;

  @media ${({ theme }) => theme.mq.tablet} {
    width: 580px;
    gap: 80px 20px;
    margin: 91px auto 96px;
  }

  @media ${({ theme }) => theme.mq.mobile} {
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 62px;
    margin: 80px 0 100px 0;
  }
`;

const CVTXCard = styled.div`
  position: relative;
  width: 398px;
  height: 314px;
  //height: 414px;

  border-radius: 20px;
  z-index: 2;
  background: ${({ theme }) => theme.colors.mainText};
  ${({ theme }) => theme.positions.flexCenterXY};
  img {
    position: absolute;
    top: -45px;
    left: calc(50% - 40px);
    width: 80px;
  }
  div {
    border: 2px solid #ddd9ff;
    border-radius: 20px;
    width: 386px;
    height: 302px;
    ${({ theme }) => theme.positions.flexCenterX};
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 41px;
    p {
      color: ${({ theme }) => theme.colors.main};
      font-size: 26px;
      font-weight: 700;
    }
    ul {
      margin-top: 8px;
      li {
        font-family: SpoqaMedium;
        font-weight: 500;
        line-height: 36px;
        font-size: 2rem;
        word-break: keep-all;
      }
    }
  }

  @media ${({ theme }) => theme.mq.tablet} {
    width: 280px;
    height: 294px;
    div {
      width: 268px;
      height: 282px;
      padding-top: 31px;
      p {
        font-size: 20px;
      }
      ul {
        li {
          font-family: SpoqaMedium;
          font-weight: 500;
          font-size: 18px;
          line-height: 30px;
        }
      }
    }
  }

  @media ${({ theme }) => theme.mq.mobile} {
    width: 320px;
    height: 230px;
    div {
      width: 300px;
      height: 210px;
      padding-top: 36px;
      p {
        font-size: 18px;
      }
      ul {
        li {
          font-family: SpoqaMedium;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
  }
`;

const Division3 = styled.img`
  position: absolute;
  left: 0;
  top: 53%;
  @media ${({ theme }) => theme.mq.tablet} {
    width: 376px;
    top: 29%;
  }
  @media ${({ theme }) => theme.mq.mobile} {
    width: 146px;
    top: 23%;
  }
`;
