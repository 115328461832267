import styled from 'styled-components';
import { left, right } from '../../../assets/img';

const Pagination = ({ total, limit, page, setPage }) => {
  const numPages = Math.ceil(total / limit);

  return (
    <>
      <Nav>
        <Button onClick={() => setPage(page - 1)} disabled={page === 1}>
          <img src={left} width={24} height={24} />
        </Button>
        {Array(numPages)
          .fill()
          .map((_, i) => (
            <Button
              key={i + 1}
              onClick={() => setPage(i + 1)}
              aria-current={page === i + 1 ? 'page' : null}
            >
              {i + 1}
            </Button>
          ))}
        <Button onClick={() => setPage(page + 1)} disabled={page === numPages}>
          <img src={right} width={24} height={24} />
        </Button>
      </Nav>
    </>
  );
};
export default Pagination;

const Nav = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  margin: 16px;
  z-index: 10;
`;

const Button = styled.button`
  font-family: SpoqaMedium, serif;
  color: black;
  font-size: 12px;
  border-radius: 50%;
  font-weight: 500;
  padding: 4px 8px;

  &:hover {
    cursor: pointer;
    transform: translateY(-2px);
  }
  &[disabled] {
    cursor: revert;
    transform: revert;
  }
  &[aria-current] {
    color: white;
    background-color: ${({ theme }) => theme.colors.main};
    cursor: revert;
    transform: revert;
  }
  img {
    margin-top: 5px;
  }
`;
