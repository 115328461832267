import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  roadmap,
  c,
  l,
  i,
  n,
  g,
  clingGra,
  wave5,
  glass4,
} from '../../../assets/img';

const Roadmap = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <SectionStyle id="4">
        <Division src={wave5} alt="wave" />
        <Division2 src={glass4} alt="division" />
        <h1>
          <img src={roadmap} alt="title" />
        </h1>
        <RoadmapStyle lang={i18n.language}>
          <hr />
          <Absolute lang={i18n.language}>
            <img src={c} alt="letter" />
            <span>PHASE 01</span>
          </Absolute>
          <Absolute2 lang={i18n.language}>
            <img src={l} alt="letter" />
            <span>PHASE 02</span>
          </Absolute2>
          <Absolute3 lang={i18n.language}>
            <img src={i} alt="letter" />
            <span>PHASE 03</span>
          </Absolute3>
          <Absolute4 lang={i18n.language}>
            <img src={n} alt="letter" />
            <span>PHASE 04</span>
          </Absolute4>
          <Absolute5 lang={i18n.language}>
            <img src={g} alt="letter" />
            <img src={clingGra} alt="letter" />
          </Absolute5>
          <section>
            <ul>
              <li>{t('phase1_1')}</li>
              <li>{t('phase1_2')}</li>
              <li>{t('phase1_3')}</li>
              <li>ㆍSmart Contract Setup</li>
            </ul>
            <ul>
              <li>{t('phase2_1')}</li>
              <li>ㆍCling App MVP</li>
              <li>{t('phase2_2')}</li>
              <li>{t('phase2_3')}</li>
            </ul>
            <ul>
              <li>{t('phase3_1')}</li>
              <li>{t('phase3_2')}</li>
              <li dangerouslySetInnerHTML={{ __html: t('phase3_3') }} />
              <li dangerouslySetInnerHTML={{ __html: t('phase3_4') }} />
            </ul>
            <ul>
              <li>{t('phase4_1')}</li>
              <li>{t('phase4_2')}</li>
              <li>{t('phase4_3')}</li>
              <li>{t('phase4_4')}</li>
            </ul>
          </section>
        </RoadmapStyle>
      </SectionStyle>
    </>
  );
};
export default Roadmap;

const SectionStyle = styled.section`
  position: relative;
  text-align: center;
  overflow: hidden;
  h1 {
    margin-top: 80px;
  }
  @media ${({ theme }) => theme.mq.tablet} {
    h1 {
      img {
        height: 75px;
      }
    }
  }
  @media ${({ theme }) => theme.mq.mobile} {
    h1 {
      margin-top: 25px;
      img {
        height: 50px;
      }
    }
  }
`;

const RoadmapStyle = styled.div`
  display: flex;
  width: ${({ lang }) => (lang === 'ko' ? '539px' : '572px')};
  position: relative;
  margin: 80px auto;
  z-index: 3;
  hr {
    margin: ${({ lang }) => (lang === 'ko' ? '' : '0 15px')};
    height: 1259px;
    width: ${({ lang }) => (lang === 'ko' ? '15px' : '25px')};
    border-width: 0;
    background: linear-gradient(#c2b9ff, #92d1f4, #5b48d9);
  }
  ul {
    text-align: left;
    font-size: 24px;
    margin-top: 20px;
    margin-bottom: 110px;
    font-weight: 700;
    :first-of-type {
      margin-top: 40px;
    }
    li {
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 40px;
    }
  }

  @media ${({ theme }) => theme.mq.tablet} {
    margin: 97px auto 80px auto;
  }

  @media ${({ theme }) => theme.mq.mobile} {
    width: 248px;
    margin: 71px auto;
    hr {
      width: ${({ lang }) => (lang === 'ko' ? '8px' : '14px')};
      margin: 12px 5px;
      height: 657px;
    }
    ul {
      :first-of-type {
        margin-top: ${({ lang }) => (lang === 'ko' ? '30px' : '15px')};
      }
      margin-left: 17px;
      font-size: 12px;
      line-height: 20px;
      margin-bottom: ${({ lang }) => (lang === 'ko' ? '67px' : '56px')};
      li {
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }
    }
  }
`;

const Absolute = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  left: ${({ lang }) => (lang === 'ko' ? '4px' : '-13px')};
  top: ${({ lang }) => (lang === 'ko' ? '-44px' : '-48px')};
  img {
    height: 76px;
    margin-right: 14px;
  }
  span {
    color: ${({ theme }) => theme.colors.main};
    font-family: Poppin700, sans-serif;
    font-size: 36px;
  }

  @media ${({ theme }) => theme.mq.tablet} {
    left: ${({ lang }) => (lang === 'ko' ? '11px' : '-6px')};
    img {
      height: 60px;
    }
  }
  @media ${({ theme }) => theme.mq.mobile} {
    left: ${({ lang }) => (lang === 'ko' ? '-8px' : '-9px')};
    top: -10px;

    img {
      height: 36px;
    }
    span {
      font-size: 16px;
    }
  }
`;

const Absolute2 = styled(Absolute)`
  top: 219px;
  @media ${({ theme }) => theme.mq.mobile} {
    top: ${({ lang }) => (lang === 'ko' ? '144px' : '134px')};
  }
`;
const Absolute3 = styled(Absolute)`
  top: 490px;
  @media ${({ theme }) => theme.mq.mobile} {
    top: ${({ lang }) => (lang === 'ko' ? '291px' : '290px')};
  }
`;
const Absolute4 = styled(Absolute)`
  top: ${({ lang }) => (lang === 'ko' ? '803px' : '834px')};
  @media ${({ theme }) => theme.mq.mobile} {
    top: ${({ lang }) => (lang === 'ko' ? '478px' : '506px')};
  }
`;
const Absolute5 = styled(Absolute)`
  top: initial;
  bottom: ${({ lang }) => (lang === 'ko' ? '-2px' : '-6px')};

  @media ${({ theme }) => theme.mq.mobile} {
    img:last-of-type {
      height: 30px;
    }
  }
`;

const Division = styled.img`
  position: absolute;
  top: -819px;
  left: calc(50% - 960px);

  @media ${({ theme }) => theme.mq.tablet} {
    transform: scale(0.55);
    top: -831px;
  }
  @media ${({ theme }) => theme.mq.mobile} {
    transform: scale(0.2);
    top: -969px;
  }
`;

const Division2 = styled.img`
  position: absolute;
  right: 0;
  top: -540px;
  @media ${({ theme }) => theme.mq.tablet} {
    height: 400px;
    top: -340px;
  }
  @media ${({ theme }) => theme.mq.mobile} {
    height: 155px;
    top: -96px;
  }
`;
