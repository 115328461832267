import styled from "styled-components";
import {
  communityTitle,
  discordBlue,
  discordUnable,
  mediumBlue,
  mediumUnable,
  telegramBlue,
  telegramUnable,
  twitterBlue,
  wave7,
} from "../../../assets/img";
import ReactTooltip from "react-tooltip";

const Community = () => {
  return (
    <CommunitySection>
      <h1>
        <img src={communityTitle} alt="title" />
      </h1>
      <div>
        <img
          onClick={() => window.open("https://twitter.com/CarrieVerse")}
          src={twitterBlue}
          alt="symbol"
        />
        <img
          onClick={() => window.open("https://discord.gg/carrieverseofficial")}
          src={discordBlue}
          alt="symbol"
        />
        <img
          onClick={() => window.open("https://t.me/CarrieVerse")}
          src={telegramBlue}
          alt="symbol"
        />
        <img
          onClick={() => window.open("https://medium.com/carrieverse")}
          src={mediumBlue}
          alt="symbol"
        />
      </div>
      <ReactTooltip
        backgroundColor="#333"
        place="top"
        effect="solid"
        id="alert"
      >
        <span>coming soon</span>
      </ReactTooltip>
      <Division2 src={wave7} alt="wave" />
    </CommunitySection>
  );
};
export default Community;

const CommunitySection = styled.section`
  position: relative;
  background-color: #fbfbff;
  padding: 80px 0;

  h1 {
    text-align: center;
  }

  div {
    margin-top: 80px;
    display: flex;
    justify-content: center;
    gap: 47px;

    img {
      width: 72px;
      height: 72px;
      z-index: 10;
      cursor: pointer;
    }
  }

  @media ${({ theme }) => theme.mq.tablet} {
    padding: 1px 0 80px 0;
    h1 {
      margin-top: 0;

      img {
        height: 71px;
      }
    }
  }

  @media ${({ theme }) => theme.mq.mobile} {
    padding: 36px 0 50px 0;
    h1 {
      img {
        height: 40px;
      }
    }

    div {
      margin-top: 50px;
      gap: 18px;

      img {
        width: 50px;
        height: 50px;
      }
    }
  }
`;

const Division2 = styled.img`
  position: absolute;
  bottom: -246px;
  left: calc(50% - 960px);

  @media ${({ theme }) => theme.mq.tablet} {
    transform: scale(0.54);
    bottom: -200px;
  }
  @media ${({ theme }) => theme.mq.mobile} {
    transform: scale(0.2);
    bottom: -307px;
  }
`;
