import React from 'react';
import styled from "styled-components";
import {event2Img, event2ImgM, iconEventNext} from "../../../assets/img";
import EventTag from "../../../component/EventTag";
import EventBtn from "../../../component/EventBtn";
import {useMediaQuery} from "react-responsive";
import {theme} from "../../../styles/theme";

const SecondEvent = () => {
    const isMobile = useMediaQuery({
        query: "(max-width: 600px)",
    });
    return (
        <SecondEventWrap>
            {!isMobile && <NextIcon><img src={iconEventNext} alt=""/></NextIcon>}
            <ContentWrap>
                <TextWrap>
                    <EventTag>Event 2</EventTag>
                    <TitleWrap>
                        <p>Stake $CVTX on ClingSwap</p>
                        <h2>Win up to 1,500 $CVTX!</h2>
                    </TitleWrap>
                    <ListWrap>
                        <ListContent>
                            <p>Details</p>
                            <ul>
                                <li>Deposit and stake at least 5,000 $CVTX during the event <br className={"onlyPc"} />for a minimum of <br className={"onlyMobile"}/>15 days.</li>
                            </ul>
                        </ListContent>
                        <ListContent>
                            <p>Prize</p>
                            <ul className={'listStyleDot'}>
                                <li>500 $CVTX to 300 winners (FCFS)</li>
                                <li>1,000 $CVTX to 50 winners through random raffle</li>
                            </ul>
                        </ListContent>
                        <ListContent>
                            <p>How to Participate</p>
                            <ul className={'listStyleNum'}>
                                <li>Purchase at least 5,000 $CVTX and stake on clingswap.io <br className={"onlyPc"} />for a minimum of 15 days.</li>
                                <li>Fill in the Google Form</li>
                            </ul>
                        </ListContent>
                    </ListWrap>
                    <BtnWrap>
                        <EventBtn bgColor={"#333"} link={"https://bit.ly/3Zr1BlK"} fontSize={"3.8889vw"}>Google Form</EventBtn>
                        <EventBtn bgColor={"#5843CC"} link={"https://bit.ly/48sLjgy"} widthValue={"42.7778vw"} fontSize={"3.8889vw"}>Cling Swap</EventBtn>
                        <EventBtn bgColor={"#2B6AFF"} link={"https://bit.ly/3RzJmbV"} widthValue={"42.7778vw"} fontSize={"3.8889vw"}>CoinMarketCap</EventBtn>
                    </BtnWrap>

                </TextWrap>
                <ImgWrap><img src={isMobile? event2ImgM : event2Img} alt=""/></ImgWrap>
            </ContentWrap>
        </SecondEventWrap>
    );
};

export default SecondEvent;

const SecondEventWrap = styled.div`
  position: relative;
  background: #5843cc0f;
  height: 942px;
  @media ${theme.mq.mobile}{
    height: 307.7778vw;
  }
`;
const NextIcon  = styled.div`
  position: absolute;
  top: -36px;
  left: 50%;
  transform: translateX(-50%);
  width: 72px;
  img {
    width: 100%;
  }
`;
const ContentWrap = styled.div`
  position: relative;
  width: 1200px;
  height: 100%;
  margin: 0 auto;
  @media ${theme.mq.mobile}{
    width: 88.8889vw;
  }
`;
const TextWrap = styled.div`
  padding-top: 120px;
  @media ${theme.mq.mobile}{
    padding-top: 11.1111vw;
  }
`;
const TitleWrap = styled.div`
  margin-bottom: 64px;
  font-size: 4.8rem;
  font-family: OneMobileTitle;
  p {
    margin-bottom: 16px;
  }
  h2 {
    display: inline-block;
    padding: 10px;
    color: #fff;
    background-color: #5843CC;
  }
  
  @media ${theme.mq.mobile}{
    margin-bottom: 105.5556vw;
    font-size: 6.1111vw;
    p {
      margin-bottom: 3.3333vw;
    }
    h2 {
      padding: 2.7778vw;
    }
  }
`;
const ListWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
  margin-bottom: 40px;
  
  @media ${theme.mq.mobile}{
    gap: 6.6667vw;
    margin-bottom: 11.1111vw;
  }
`;
const ListContent = styled.div`
    p {
      margin-bottom: 12px;
      font-size: 2.0rem;
      font-family: OneMobileTitle;
      font-weight: 400;
    }
   ul {
     li {
       line-height: 1.4;
       font-size: 2.0rem;
       font-family: OneMobile;
     }
     &.listStyleDot {
       display: flex;
       flex-direction: column;
       gap: 8px;
       padding-left: 24px;
       list-style: disc;
     }
     &.listStyleNum {
       display: flex;
       flex-direction: column;
       gap: 8px;
       padding-left: 24px;
       list-style: decimal;
     }
   }
  @media ${theme.mq.mobile}{
    p {
      margin-bottom: 2.2222vw;
      font-size: 4.1667vw;
    }
    ul {
      li {
        font-size: 4.1667vw;
        &.listStyleDot {
          gap: 0;
          padding-left: 2.7778vw;
        }
        &.listStyleNum {
          gap: 0;
          padding-left: 5.2778vw;
        }
      }
    }
  }
`;
const BtnWrap = styled.div`
  display: flex;
  gap: 12px;
  @media ${theme.mq.mobile}{
    row-gap: 3.3333vw;
    column-gap: 2.2222vw;
    flex-wrap: wrap;
  }
`;

const ImgWrap = styled.div`
  position: absolute;
  width: 420px;
  right: -40px;
  top: 160px;
  img {
    width: 100%;
  }
  @media ${theme.mq.mobile}{
    width: 88.8889vw;
    top: 57.7778vw;
    right: 50%;
    transform: translateX(50%);
    img {
      width: 100%;
    }
  }
`;