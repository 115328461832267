import React from 'react';
import styled from "styled-components";
import {theme} from "../../../styles/theme";

const EventFooter = () => {
    return (
        <FooterWrap>
            <InnerWrap>
                <Title>Notice</Title>
                <Description>
                    <p>Random snapshots will be taken during the <br className={"onlyMobile"}/>event to filter malicious activities.</p>
                    <p>You must stake $CVTX for at least 15 days during <br className={"onlyMobile"}/>the event to win.</p>
                    <p>Winners will be disqualified if caught conducting <br className={"onlyMobile"}/>malicious activities.</p>
                    <p>We hope the best for you!</p>
                </Description>
            </InnerWrap>
        </FooterWrap>
    );
};

export default EventFooter;

const FooterWrap = styled.div`
  background-color: #333;
  color: #fff;
`;
const InnerWrap = styled.div`
  padding: 48px 0;
  width: 1200px;
  margin: 0 auto;
  @media ${theme.mq.mobile}{
    width: 88.8889vw;
    padding: 5.5556vw 0 8.3333vw;
  }
`;
const Title = styled.h2`
  padding-bottom: 12px;
  font-size: 1.8rem;
  border-bottom: 1px solid #fff;
  font-family: OneMobileTitle;
  @media ${theme.mq.mobile}{
    padding-bottom: 2.2222vw;
    font-size: 4.44444vw;
  }
`;
const Description = styled.div`
    padding-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  p {
    font-size: 1.6rem;
    font-family: OneMobile;
  }
  @media ${theme.mq.mobile}{
    padding-top: 3.3333vw;
    gap: 2.2222vw;
    p {
      font-size: 3.6111vw;
      line-height: 1.4;
    }
  }
`;