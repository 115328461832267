import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { auth } from "../../../lib/fbase";
import { tokenStore, userStore } from "../../../store/admin";
import { contentsReplaceNewline, formatDate } from "../../../utills/formatData";
import { fetchBoardData } from "../../../api";

const List = () => {
  const navigate = useNavigate();
  const [viewContents, setViewContents] = useState([]);
  const { userUid, setUserUid } = userStore();
  const { isLogged, setIsLogged } = tokenStore();

  useEffect(() => {
    const getBoardData = async () => {
      const boardData = await fetchBoardData();
      setViewContents(boardData);
    };
    getBoardData();
  }, []);

  const googleSignOut = async () => {
    setUserUid(null);
    setIsLogged(null);
    await auth.signOut();
  };

  return (
    <StyledList>
      <Header>
        {userUid && (
          <Link to="/clingAdmin/edit">
            <button>글쓰기</button>
          </Link>
        )}
        {isLogged && <button onClick={googleSignOut}>로그아웃</button>}
      </Header>
      <ListWrapper>
        <section>
          {viewContents.map((item) => (
            <article onClick={() => navigate(`/clingAdmin/detail/${item.id}`)}>
              <img src={item.thumbnailUrl} alt="pic" />
              <div>
                <h1>{item.title_ko}</h1>
                <h2>{formatDate(item.date.seconds)}</h2>
                <p>{contentsReplaceNewline(item.contentPreview_ko)}</p>
              </div>
            </article>
          ))}
        </section>
      </ListWrapper>
    </StyledList>
  );
};
export default List;

const StyledList = styled.div`
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.main};
  padding-top: 10px;
`;

const Header = styled.header`
  text-align: right;
  margin: 15px 100px 15px 0;
  button {
    margin-left: 10px;
    font-size: 16px;
    font-weight: bold;
    :hover {
      color: black;
    }
  }
`;

const ListWrapper = styled.main`
  ${({ theme }) => theme.positions.flexCenterY};
  section {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 15px;
    margin: 0 auto;
    article {
      background: #38393b;
      width: 400px;
      height: 500px;
      cursor: pointer;
      img {
        width: 100%;
        height: 300px;
      }
      div {
        padding: 5px;
        h1 {
          font-size: ${({ theme }) => theme.fontSizes.medium};
          margin: 10px 0;
        }
        h2 {
          font-size: ${({ theme }) => theme.fontSizes.paragraph};
          color: ${({ theme }) => theme.colors.grey};
        }
        p {
          margin-top: 10px;
          font-size: ${({ theme }) => theme.fontSizes.paragraph};
          line-height: normal;
          white-space: pre-line;
          word-break: keep-all;
        }
      }
    }
  }
`;
