import { ThemeProvider } from "styled-components";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { GlobalStyle } from "./styles/global";
import { theme } from "./styles/theme";
import "./assets/fonts/font.css";
import "../src/lang/i18n";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <App />
  </ThemeProvider>
);
