const EnglishContent = ({ getValue, boardContent }) => {
  return (
    <>
      <input
        autoComplete="off"
        defaultValue={boardContent?.title_en}
        name="title_en"
        onChange={getValue}
        type="text"
        placeholder="title"
      />
      <textarea
        defaultValue={boardContent?.content_en}
        onChange={getValue}
        name="content_en"
        placeholder="content"
      />
    </>
  );
};
export default EnglishContent;
