import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Header from "./component/Header";
import Footer from "./component/Footer";
import { useEffect } from "react";
import { clickHandler, getBrowserLang } from "./utills/common";
import Login from "./pages/Admin/Login";
import Detail from "./pages/Detail";
import List from "./pages/Admin/List";
import Edit from "./pages/Admin/Edit";
import Event from "./pages/Event";
import MainLayout from "./pages/MainLayout";

function App() {
  useEffect(() => {
    if (getBrowserLang() === "ko") clickHandler("ko");
    else if (getBrowserLang() === "en") clickHandler("en");
  }, []);

  return (
    <BrowserRouter>
      <Routes>
          <Route element={<MainLayout />}>
              <Route path="/" element={<Home />} />
              <Route path="/detail/:id" element={<Detail />} />
              <Route path="/clingAdmin" element={<Login />} />
              <Route path="/clingAdmin/list" element={<List />} />
              <Route path="/clingAdmin/edit" element={<Edit />} />
          </Route>
          {/*<Route path={"/event"} element={<Event />} />*/}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
