import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import { theme } from './theme';

export const GlobalStyle = createGlobalStyle`
  ${reset};
  html {
    font-size: 62.5%;
    @media ${({ theme }) => theme.mq.mobile} {
      font-size: 37.5%;
    }
  }
    body {
      background: white;
      color:#333333;
      font-family: SpoqaMedium, Poppin400,sans-serif;

      * {
        box-sizing: border-box;
        line-height: normal;
      }
      //::-webkit-scrollbar {
      //  display: none;
      //}
      a {
        text-decoration: none;
        color:white;
      }
      button {
        cursor: pointer;
        border: none;
        background: none;
        color: white;
      }
    }
    .onlyPc {
      display: block;
    }
    .onlyMobile {
      display: none;
    }
    @media ${theme.mq.mobile} {
      .onlyPc {
        display: none;
      }
      .onlyMobile {
        display: block;
      }
    }
`;
