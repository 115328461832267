import styled from "styled-components";
import {
  partner,
  investor,
  ring2,
  circle3,
  desktopPolygon,
  desktopCentralInvestmentPartners,
  desktopPolygonstudios,
  desktopMintway,
  desktopNFTb,
  desktopSAC,
  desktopHorizon,
  desktopPopsline,
  desktopFortunade,
  desktopYuliverse,
  desktopHeroeswar,
  desktopTnt,
  mPolygon,
  mCentralInvestmentPartners,
  mPolygonstudios,
  mMintway,
  mNFTb,
  mSAC,
  mHorizon,
  desktopCertik,
  mPopsline,
  mCertik,
  mFortunade,
  mYuliverse,
  mTnt,
  mHeroeswar, mDmcc, desktopDmcc, mBybit, desktopBybit,
} from "../../../assets/img";
import Community from "../roadmap/Community";
import { useEffect, useState } from "react";
import {
  fetchClingInvestorsData,
  fetchClingPartnershipsData,
} from "../../../api";

const Partner = ({ isMobile, isTablet }) => {
  const [partnerList, setPartnerList] = useState([]);
  const [investorList, setInvestorList] = useState([]);

  useEffect(() => {
    const getBoardData = async () => {
      const partnerData = await fetchClingPartnershipsData();
      const investorData = await fetchClingInvestorsData();
      setPartnerList(partnerData.reverse());
      setInvestorList(investorData.reverse());
    };
    getBoardData();
  }, []);

  return (
    <PartnerSection id="6">
      <Division src={circle3} alt="division" />
      <Division2 src={ring2} alt="division" />
      <h1>
        <img src={partner} alt="title" />
      </h1>
      <FlexWrap>
        <div>
          <img src={isMobile ? mDmcc : desktopDmcc} alt="logo" />
        </div>
        {/*<div>*/}
        {/*  <img src={isMobile ? mBybit : desktopBybit} alt="logo" />*/}
        {/*</div>*/}
        <div>
          <img src={isMobile ? mPolygon : desktopPolygon} alt="logo" />
        </div>
        <div>
          <img
            src={isMobile ? mPolygonstudios : desktopPolygonstudios}
            alt="logo"
          />
        </div>
        <div>
          <img src={isMobile ? mMintway : desktopMintway} alt="logo" />
        </div>
        <div>
          <img src={isMobile ? mNFTb : desktopNFTb} alt="logo" />
        </div>
        <div>
          <img src={isMobile ? mSAC : desktopSAC} alt="logo" />
        </div>
        <div>
          <img src={isMobile ? mHorizon : desktopHorizon} alt="logo" />
        </div>
        <div>
          <img src={isMobile ? mPopsline : desktopPopsline} alt="logo" />
        </div>
        <div>
          <img src={isMobile ? mCertik : desktopCertik} alt="logo" />
        </div>
        <div>
          <img src={isMobile ? mFortunade : desktopFortunade} alt="logo" />
        </div>
        <div>
          <img src={isMobile ? mYuliverse : desktopYuliverse} alt="logo" />
        </div>
        <div>
          <img src={isMobile ? mTnt : desktopTnt} alt="logo" />
        </div>
        <div>
          <img src={isMobile ? mHeroeswar : desktopHeroeswar} alt="logo" />
        </div>
      </FlexWrap>
      <h1>
        <img src={investor} alt="title" />
      </h1>
      <FlexWrap2>
        <div>
          <img src={isMobile ? mPolygon : desktopPolygon} alt="logo" />
        </div>
        <div>
          <img
            src={
              isMobile
                ? mCentralInvestmentPartners
                : desktopCentralInvestmentPartners
            }
            alt="logo"
          />
        </div>
      </FlexWrap2>
      <Community />
    </PartnerSection>
  );
};
export default Partner;

const PartnerSection = styled.section`
  overflow: hidden;
  padding: 80px 0 0 0;
  position: relative;
  background-color: #f5f5ff;

  h1 {
    text-align: center;
    margin-top: 0 !important;
    margin-bottom: 80px;
  }

  @media ${({ theme }) => theme.mq.tablet} {
    h1 {
      margin-bottom: 62px;

      img {
        height: 73px;
      }
    }
  }
  @media ${({ theme }) => theme.mq.mobile} {
    padding: 36px 0 0 0;
    h1 {
      margin-bottom: 50px;

      img {
        height: 40px;
      }
    }
  }
`;

const FlexWrap = styled.div`
  margin: auto;
  width: 1000px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 80px 94px;
  margin-bottom: 195px;

  img {
    width: 248px;
    height: 90px;
  }

  @media ${({ theme }) => theme.mq.tablet} {
    width: 830px;
    margin-bottom: 80px;
    gap: 66px;
    img {
      width: 200px;
      height: 70px;
    }
  }
  @media ${({ theme }) => theme.mq.mobile} {
    display: grid;
    grid-template-columns: 1fr 1fr;

    width: 230px;
    gap: 16px 10px;
    margin-bottom: 61px;
    img {
      width: 100px;
      height: 26px;
    }
  }
`;

const FlexWrap2 = styled(FlexWrap)`
  gap: 60px 94px;
  margin-bottom: 168px;

  img {
    width: 248px;
    height: 90px;
  }

  @media ${({ theme }) => theme.mq.tablet} {
    gap: 66px;
    img {
      width: 200px;
      height: 70px;
    }
  }
  @media ${({ theme }) => theme.mq.mobile} {
    margin-bottom: 25px;
    gap: 16px 30px;
    img {
      width: 100px;
      height: 26px;
    }
  }
`;

const Division = styled.img`
  position: absolute;
  left: 94px;
  top: 65px;
  @media ${({ theme }) => theme.mq.tablet} {
    left: 0;
  }
  @media ${({ theme }) => theme.mq.mobile} {
    width: 60px;
    left: 30px;
  }
`;

const Division2 = styled.img`
  position: absolute;
  left: 0;
  top: -179px;
  @media ${({ theme }) => theme.mq.tablet} {
    width: 400px;
    top: -100px;
  }
  @media ${({ theme }) => theme.mq.mobile} {
    width: 220px;
    top: -50px;
    left: -35px;
  }
`;
