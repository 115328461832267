import { useNavigate } from "react-router-dom";
import { pageNumberStore } from "../store/admin";

const UseNav = () => {
  const { setPageNumber } = pageNumberStore();
  const navigate = useNavigate();

  const goToClickedNumber = (menu) => {
    setPageNumber(menu);
    navigate("/");
  };
  return { goToClickedNumber };
};

export default UseNav;
