import React from 'react';
import styled from "styled-components";
import {theme} from "../../styles/theme";

const EventBtn = ({children, bgColor, link, widthValue, fontSize}) => {
    return (
        <StyledSelf bgColor={bgColor} widthValue={widthValue} fontSize={fontSize}>
            <a href={link} target={"_blank"}>{children}</a>
        </StyledSelf>
    );
};

export default EventBtn;

const StyledSelf = styled.div`
  width: 220px;
  height: 72px;
  border-radius: 100px;
  background-color: ${({bgColor}) => bgColor};
  a {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 2.0rem;
    font-family: OneMobileTitle;
  }
  @media ${theme.mq.mobile}{
    width: ${({widthValue}) => widthValue? widthValue : '88.8889vw'};
    height: 15.5556vw;
    a {   
      font-size: ${({fontSize}) => fontSize? fontSize : '4.1667vw'};
      white-space: nowrap;
    }
  }
`;