import styled, { css } from 'styled-components';

const Description = ({ children, position }) => {
  return <StyledDescription position={position}>{children}</StyledDescription>;
};

Description.defaultProps = {
  position: 'left',
};

export default Description;

const positions = {
  left: css`
    text-align: left;
    box-shadow: 5px 5px #dcdcf9;
    img {
      right: 0;
    }
  `,
  right: css`
    text-align: right;
    box-shadow: -5px 5px #dcdcf9;
    img {
      left: initial;
      right: 0;
    }
    @media ${({ theme }) => theme.mq.mobile} {
      img {
        left: 0;
      }
    }
  `,
};

const StyledDescription = styled.div`
  position: relative;
  width: 445px;
  height: 422px;
  background-color: #fafaff;
  border-radius: 30px;
  z-index: 2;
  overflow: hidden;
  padding: 40px;

  :nth-of-type(1) > p {
    margin-left: 22px;
  }
  :nth-of-type(3) > p {
    margin-left: 14px;
  }
  :nth-of-type(2) > p {
    margin-right: -20px;
  }
  :nth-of-type(4) > p {
    margin-right: 10px;
  }
  ${({ position }) => css`
    ${positions[position]};
  `};

  h1 {
    font-family: Odibee, sans-serif;
    font-size: 180px;
    color: ${({ theme }) => theme.colors.main};
    line-height: 141px;
    padding-top: 0 !important;
  }
  img {
    position: absolute;
    top: 0;
    height: 100%;
  }
  p {
    font-family: NotoSansBold, sans-serif;
    font-size: 24px;
    margin: 12px 0 16px 0;
    b {
      color: ${({ theme }) => theme.colors.main};
    }
  }
  h3,
  li {
    font-weight: 500;
    font-size: 18px;
    white-space: nowrap;
  }
  h3 {
    color: ${({ theme }) => theme.colors.main};
    font-family: SpoqaBold, Poppin700, sans-serif;
    font-weight: bold;
    white-space: nowrap;
  }
  li {
    font-weight: 500;
    line-height: 30px;
  }
  @media ${({ theme }) => theme.mq.mobile} {
    width: 316px;
    height: 360px;
    p {
      font-size: 18px;
      margin: 12px 0 20px 0;
    }
    :nth-of-type(1) > h1 {
      margin-left: 13px;
    }
    :nth-of-type(3) > h1 {
      margin-left: 24px;
    }
    :nth-of-type(4) > p {
      margin-right: 36px;
    }
    :nth-of-type(1) > p,
    :nth-of-type(3) > p {
      margin-left: 36px;
    }
    :nth-of-type(4) > p {
      margin-right: 40px;
    }
    h1 {
      font-size: 150px;
      padding-top: 40px;
    }
    h3,
    li {
      font-size: 14px;
    }
    li {
      line-height: 22px;
    }
  }
`;
