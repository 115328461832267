import React from 'react';
import styled from "styled-components";
import Intro from "./Intro";
import FirstEvent from "./FirstEvent";
import SecondEvent from "./SecondEvent";
import EventFooter from "./EventFooter";

const Event = () => {
    return (
        <EventWrap>
            <Intro />
            <FirstEvent />
            <SecondEvent />
            <EventFooter />
        </EventWrap>
    );
};

export default Event;

const EventWrap = styled.div`
`;