import styled from 'styled-components';
import { SectionTemplate } from './WhatIsCling';
import { clingFeature, wave1, wave2 } from '../../../assets/img';
import Description from './component/Description';
import { FEATURE_DATA } from '../../../utills/staticData';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';

const ClingFeature = () => {
  const { i18n } = useTranslation();

  const isCapitalLetter = (str) => {
    return `${str.replace(/([A-Z])/g, '<b>$1</b>')}`;
  };
  const isMobile = useMediaQuery({
    query: '(max-width: 600px)',
  });
  const FEATURES = FEATURE_DATA(isMobile);

  return (
    <SectionTemplate>
      <h1>
        <img src={clingFeature} alt="title" />
      </h1>
      <Division src={wave1} alt="division" />
      <StyledH2>W.X.Y.Z</StyledH2>
      <DescriptionWrapper>
        {FEATURES.map((item, index) => (
          <Description position={item.position} key={index}>
            <h1>{item.h1}</h1>
            <img src={item.src} alt="glass" />
            <p dangerouslySetInnerHTML={{ __html: isCapitalLetter(item.p) }} />
            <div>
              {i18n.language === 'ko' ? (
                <h3>{item.h3}</h3>
              ) : (
                <h3>{item.h3_en}</h3>
              )}
              {i18n.language === 'ko'
                ? item.list.map((li, index) => (
                    <ul key={index}>
                      <li>{li}</li>
                    </ul>
                  ))
                : item.list_en.map((li, index) => (
                    <ul key={index}>
                      <li>{li}</li>
                    </ul>
                  ))}
            </div>
          </Description>
        ))}
      </DescriptionWrapper>
      <Division2 src={wave2} alt="division" />
    </SectionTemplate>
  );
};
export default ClingFeature;

const Division = styled.img`
  position: absolute;
  top: -412px;
  left: calc(50% - 960px);

  @media ${({ theme }) => theme.mq.tablet} {
    transform: scale(0.55);
    top: -448px;
  }
  @media ${({ theme }) => theme.mq.mobile} {
    top: -480px;
    transform: scale(0.36);
  }
`;

const StyledH2 = styled.h2`
  font-size: 4.6rem;
  margin: 50px 0 82px 0;
  font-family: NotoSansBold;

  @media ${({ theme }) => theme.mq.tablet} {
    font-size: 36px;
    margin: 40px 0 80px 0;
  }
  @media ${({ theme }) => theme.mq.mobile} {
    margin: 22px 0 50px 0;
  }
`;

const DescriptionWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  width: 935px;
  gap: 55px 45px;

  @media ${({ theme }) => theme.mq.tablet} {
    flex-direction: column;
    width: fit-content;
  }
`;

const Division2 = styled.img`
  position: absolute;
  left: calc(50% - 2242px);
  bottom: -2936px;
  @media ${({ theme }) => theme.mq.tablet} {
    transform: scale(0.4);
    bottom: -2096px;
  }
  @media ${({ theme }) => theme.mq.mobile} {
    transform: scale(0.23);
    bottom: -2143px;
  }
`;
